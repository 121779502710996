const Authentication = () => import(/* webpackChunkName: "auth" */ "@/component/user/auth/Authentication");
const Login = () => import(/* webpackChunkName: "auth" */ "@/component/user/auth/login/Login");
const SignUp = () =>
  import(/* webpackChunkName: "auth" */ "@/component/user/auth/signUp/SignUp");
const PasswordRecovery = () =>
  import(/* webpackChunkName: "auth" */ "@/component/user/auth/login/PasswordRecovery");

export default [
  {
    path: "/",
    component: Authentication,
    meta: {
      // requiresGuest: true,
      requiresAuth: false,
    },
    children: [
      {
        path: "presenter-votre-carte-d-electeur",
        name: "Login",
        component: Login,
        meta: {
          // title: "Login",
          requiresGuest: true,
        },
      },
      {
        path: "creer-sa-carte-d-electeur",
        name: "SignUp",
        component: SignUp,
        meta: {
          // title: "Sign Up",
          requiresGuest: true,
        },
      },
      {
        path: "recovery",
        name: "PasswordRecovery",
        component: PasswordRecovery,
        meta: {
          // title: "PasswordRecovery",
          requiresGuest: true,
        },
      },
    ],
  },
];
