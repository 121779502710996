import { setToFirestore } from "firewings";
import {
  collection,
  setDoc,
  doc,
  serverTimestamp,
  getDoc,
  getDocs,
  query,
  limit,
  orderBy,
  increment,
} from "firebase/firestore";
if (process.env.VUE_APP_MODE == "production") {
  //import { Auth } from '@/firebase/production/auth';
  require("@/firebase/production/db");
} else require("@/firebase/development/db");
let D;
if (process.env.VUE_APP_MODE == "production") {
  //import { Auth } from '@/firebase/production/auth';
  D = require("@/firebase/production/db.js");
} else {
  D = require("@/firebase/development/db.js");
}
const DB = D.DB;

/**
 * myVoteData
 * {
  "test": 1,
  "name": "Eric POUBANNE",
  "voteCount": 4,
  "rank": -1,
  "one": 68,
  "two": 82,
  "result": {},
  "route": {
    "name": "vote",
    "params": {
      "id": "eric-poubanne",
      "country": "fr",
      "zone": "dourdan",
      "theme": "municipales-2020"
    }
  },
  "id": "eric-poubanne",
  "path": "users/VYBhv0U27QV5XcvQjuzsdAuLYHN2/votes/eric-poubanne"
}
 */

const aVote = class aVotez {
  // constructor(myVoteData, userId, voteId, votePath, DB, FieldValue, Timestamp) {
  constructor(myVoteData, userId, voteId, votePath, DB, FieldValue) {
    let myVote;
    this.myVoteData = myVoteData;
    this.userId = userId;
    this.voteId = voteId;
    this.votePath = votePath;
    this.DB = DB;
    this.FieldValue = FieldValue;
    //this.Timestamp = Timestamp; //unused ??
  }

  async updateUserVote() {
    const refUserVote = doc(DB, "users/" + this.userId + "/votes", this.voteId);
    //const voteSnapshot = await getDoc(docRef);
    // const refUserVote = await this.DB.collection(
    //     "users/" + this.userId + "/votes"
    // ).doc(this.voteId);

    // refUserVote.get().then((doc) => {
    //     if (doc.exists) {
    //         console.log("Document data:", doc.data());
    //     } else {
    //         // doc.data() will be undefined in this case
    //         console.log("No such document!");
    //     }
    // }).catch((error) => {
    //     console.log("Error getting document:", error);
    // });
    this.myVote = await setToFirestore(
      refUserVote,
      this.myVoteData,
      null,
      {
        merge: true,
      },
      setDoc
    );
    return this.myVote;
  }

  async updateVoteCount() {
    const voteCountData = {};
    var mergeFieldz = [
      "one",
      "two",
      "updatedAt",
      "voteCount",
      "nbVoteSinceLastCalculation",
    ];
    if (this.myVote.voteCount == 1) {
      voteCountData.addToNbVoters = true;
      mergeFieldz.push("addToNbVoters");
    }
    voteCountData.one = this.myVote.one;
    voteCountData.two = this.myVote.two;

    voteCountData.updatedAt = serverTimestamp();
    // voteCountData.updatedAt = this.FieldValue.serverTimestamp();
    // voteCountData.updatedAt = this.Timestamp.now()
    // le compteur du nombre de fois que le voteur vote,
    // on le réplique ici pour requeter facilement a partir d'un vote
    voteCountData.voteCount = this.myVote.voteCount;
    voteCountData.nbVoteSinceLastCalculation = increment(1);
    // voteCountData.nbVoteSinceLastCalculation = this.FieldValue.increment(1);
    // voteCountData.initial_one = (this.myVote.initial_one) ? this.myVote.initial_one : 0
    // voteCountData.initial_two = (this.myVote.initial_two) ? this.myVote.initial_two : 0

    const refVoteCount = doc(DB, this.votePath + "/voteCount", this.userId);
    // const refVoteCount = await this.DB.collection(
    //     this.votePath + "/voteCount"
    // ).doc(this.userId);

    // Cela ne fonctionne pas car le security rules empêche la lecture
    // refVoteCount.get().then((doc) => {
    //     if (doc.exists) {
    //         console.log("Document data:", doc.data());
    //     } else {
    //         // doc.data() will be undefined in this case
    //         console.log("No such document!");
    //     }
    // }).catch((error) => {
    //     console.log("Error getting document:", error);
    // });

    await setDoc(refVoteCount, voteCountData, { mergeFields: mergeFieldz });
  }

  async voteStatusToPending() {
    const refVote = doc(
      DB,
      this.votePath.replace("/" + this.voteId, ""),
      this.voteId
    );
    // const refVote = this.DB.collection(
    //     this.votePath.replace("/" + this.voteId, "")
    // ).doc(this.voteId);
    // TODO (A VERIFIER) : mettre dans la rule qu'on ne peut incrementer que de 1 à la fois ou de rien mais pas autres (+2, -1...)
    let voteData = {
      pending: true,
      nbVoteSinceLastCalculation: increment(1),
      nbVote: increment(1),
    };
    // TODO (A VERIFIER) : ne pas permettre dans rules d'incrémenter plus d'une fois par le user
    // 13/01/2022 : maintenant il n'y a qu'un nbVoters : on ne différencie plus même si c'est un vote officiel importé,
    // car dans ce cas, on initialise le contre avec le même score que le pour
    // ca saute : if (this.myVote.voteCount == 1) voteData['choice']['one']['nbVoters'] = this.FieldValue.increment(1);
    // ca saute aussi : if (this.myVote.voteCount == 1) voteData['choice']['two']['nbVoters'] = this.FieldValue.increment(1);
    // DOUBLON AVEC VOTECALCULATION  => MAJ SECURITY RULES ??????
    //if (this.myVote.voteCount == 1) voteData['nbVoters'] = this.FieldValue.increment(1);

    await setDoc(refVote, voteData, { merge: true });
  }

  async addVoteToQueue() {
    // const refVoteQueue = doc(DB, "country/fr/voteQueue", this.votePath.replaceAll("/", "-"));
    const refVoteQueue = doc(
      DB,
      "voteQueue",
      this.votePath.replaceAll("/", "-")
    );
    // const refVoteQueue = this.DB.collection("country/fr/voteQueue").doc(this.votePath.replaceAll("/", "-"));
    // const refVoteQueue = this.DB.collection("country/fr/voteQueue").doc(this.votePath.replace("/", "-"));
    // TODO (A VERIFIER) : mettre dans la rule qu'on ne peut incrementer que de 1 à la fois ou de rien mais pas autres (+2, -1...)
    let voteQueueData = {
      voteId: this.voteId,
      votePath: this.votePath.replace("/" + this.voteId, ""),
      createdAt: serverTimestamp(),
    };
    await setDoc(refVoteQueue, voteQueueData, { merge: true });
  }
};

export default aVote;
