export default {
  worldChoice: (state) => state.worldChoice,
  worldCountryChoice: (state) => state.worldCountryChoice,
  paramsForRoute: (state) => {
    let params = {};
    if (state.worldCountry != "") params.worldCountry = state.worldCountry;
    if (state.worldCountryZone != "")
      params.worldCountryZone = state.worldCountryZone;
    if (state.worldCountryZoneTheme != "")
      params.worldCountryZoneTheme = state.worldCountryZoneTheme;
    if (state.worldCountryTheme != "")
      params.worldCountryTheme = state.worldCountryTheme;
    if (state.worldTheme != "") params.worldTheme = state.worldTheme;
    if (state.worldZone != "") params.worldZone = state.worldZone;
    if (state.worldZoneTheme != "")
      params.worldZoneTheme = state.worldZoneTheme;
    if (state.worldCountryZoneThemeVote != "")
      params.worldCountryZoneThemeVote = state.worldCountryZoneThemeVote;

    return params;
  },
  breadcrumb: (state, getters, rootState) => {
    let breadcrumb = [];
    let worldArray = [];
    let wcOrWtOrWzArray = [];
    let voteListArray = [];
    let voteArray = [];
    let targetFirstRoutes = ["worldCountry", "worldZoneTheme", "worldTheme"];
    let targetSecondRoutes = [
      "worldCountryThemeVoteList",
      "worldZoneTheme",
      "worldTheme",
    ];

    breadcrumb.push({
      text: "world",
      exact: true,
      disabled: false,
      to: {
        name: "world",
      },
    });

    /**
     * 1er fil d'ariane : le monde
     */
    //  if (targetFirstRoutes.includes(rootState.route.name)) {
    //     let to = {};
    //     to.params = [];
    //     to['name'] = rootState.route.name
    //     to.params[rootState.route.name] = state[rootState.route.name]
    //     breadcrumb.push({
    //       text: state[rootState.route.name + 'Name'],
    //       exact: true,
    //       disabled: false,
    //       to
    //     }
    //     );
    //   };

    if (rootState.route.name == "worldCountry") {
      breadcrumb.push({
        text: state.worldCountry,
        exact: true,
        disabled: false,
        to: {
          name: "worldCountry",
          params: { worldCountry: state.worldCountry },
        },
      });
    }
    if (rootState.route.name == "worldZone") {
      breadcrumb.push(
        {
        text: "zones",
        exact: true,
        disabled: false,
        to :{
          name: "world"
        }
      });
    }
    if (rootState.route.name == "worldTheme") {
      breadcrumb.push({
        text: "themes",
        exact: true,
        disabled: true,
      });
    }

    /**
     * 2e fil d'ariane
     */
    if (rootState.route.name == "worldZoneTheme") {
      breadcrumb.push(
        {
          text: "zones",
          exact: true,
          disabled: false,
          to: {
            name: "worldZone",
            params: { worldZone: state.worldZone },
          },
        },
        {
          text: state.worldZone,
          exact: true,
          disabled: true,
          to: {
            name: "worldZone",
            params: { worldZone: state.worldZone },
          },
        }
      );
    }
    if (rootState.route.name == "worldThemeVoteList") {
      breadcrumb.push(
        {
        text: "themes",
        exact: true,
        disabled: false,
        to: {
          path: "/themes",
          // params: { worldTheme: state.worldTheme },
        },        
        // to: {
        //   name: "worldTheme",
        //   params: { worldTheme: state.worldTheme },
        // },
      },
      {
        text: state.worldTheme,
        exact: true,
        disabled: true,
      },
      );
    }
    if (rootState.route.name == "worldCountryTheme") {
      breadcrumb.push({
        text: state.worldCountry,
        exact: true,
        disabled: false,
        to: {
          name: "worldCountry",
          params: {
            worldCountry: state.worldCountry,
          },
        },
      });
    }
    if (rootState.route.name == "worldCountryZone") {
      breadcrumb.push(
        {
          text: state.worldCountry,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountry",
            params: { worldCountry: state.worldCountry },
          },
        },
        {
          text: "zones",
          exact: true,
          disabled: false,       
        }
      );
    }

    /**
     * 3e fil d'ariane
     */
    if (rootState.route.name == "worldCountryThemeVoteList" || rootState.route.name == "worldCountryThemeVoteListResult") {
      breadcrumb.push(
        {
          text: state.worldCountry,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountry",
            params: {
              worldcountry: state.worldCountry,
            },
          },
        },
        {
          text: state.worldCountryTheme,
          exact: true,
          disabled: true,
        }
      );
    }
    if (rootState.route.name == "worldZoneThemeVoteList") {
      breadcrumb.push(
        {
          text: "zones",
          exact: true,
          disabled: false,
          to: {
            name: "worldZone",
            params: {
              worldZone: state.worldZone,
            },
          },
        },
        {
          text: state.worldZone,
          exact: true,
          disabled: true,
          to: {
            name: "worldZoneTheme",
            params: {
              worldZone: state.worldZone,
              worldZoneTheme: state.worldZoneTheme,
            },
          },
        },
        {
          text: state.worldZoneTheme,
          exact: true,
          disabled: true,
        }
      );
    }
    if (rootState.route.name == "worldThemeVote") {
      breadcrumb.push(
        {
        text: "themes",
        exact: true,
        disabled: false,
        to: {
          name: "worldTheme",
          params: { worldTheme: state.worldTheme },
        },
      },
      {
        text: state.worldTheme,
        exact: true,
        disabled: false,
        to: {
          name: "worldThemeVoteList",
          params: { worldTheme: state.worldTheme },
        },
      },      
      {
        text: rootState.vote.vote.id,
        exact: true,
        disabled: true,
      },
      );
    }    
    
    if (rootState.route.name == "worldZoneThemeVote") {
      breadcrumb.push({
        text: "zones",
        exact: true,
        disabled: false,
        to: {
          name: "worldZone",
          params: {
            worldZone: state.worldZone,
          },
        },
      },
      {
        text: state.worldZone,
        exact: true,
        disabled: false,
        to: {
          name: "worldZoneTheme",
          params: {
            worldZone: state.worldZone,
            worldZoneTheme: state.worldZoneTheme,
          },
        },
      },
      {
        text: state.worldZoneTheme,
        exact: true,
        disabled: true,
      }
    );
    }
    // if (rootState.route.name == "worldCountryZoneThemeVoteList") {
    //   breadcrumb.push(
    //     {
    //       text: state.worldCountry,
    //       exact: true,
    //       disabled: false,
    //       to: {
    //         name: "worldCountry",
    //         params: {
    //           worldCountry: state.worldCountry,
    //         },
    //       },
    //     },
    //     {
    //       text: state.worldZone,
    //       exact: true,
    //       disabled: false,
    //       to: {
    //         name: "worldZone",
    //         params: {
    //           worldCountry: state.worldCountry,
    //           worldZone: state.worldZone,
    //         },
    //       },
    //     },
    //     {
    //       text: state.worldCountryTheme,
    //       exact: true,
    //       disabled: true,
    //     }
    //   );
    // }
    if (rootState.route.name == "worldCountryZoneTheme") {
      breadcrumb.push(
        {
          text: state.worldCountry,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountry",
            params: {
              worldCountry: state.worldCountry,
            },
          },
        },
        {
          text: "zones",
          exact: true,
          disabled: false,
          to: {
            name: "worldCountryZone",
            params: {
              worldCountry: state.worldCountry,
            },
          },
        },        
        {
          text: state.worldCountryZone,
          exact: true,
          disabled: true,
        }
      );
    }

    /**
     * 4e fil d'ariane
     */
    if (rootState.route.name == "worldCountryThemeVote") {
      breadcrumb.push(
        {
          text: state.worldCountry,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountry",
            params: {
              worldCountry: state.worldCountry,
            },
          },
        },
        {
          text: state.worldCountryTheme,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountryThemeVoteList",
            params: {
              worldCountry: state.worldCountry,
              worldCountryTheme: state.worldCountryTheme,
            },
          },
        },
        {
          text: rootState.vote.vote.name,
          exact: true,
          disabled: true,
        }
      );
    }
    if (rootState.route.name == "worldCountryZoneThemeVoteList") {
      breadcrumb.push(
        {
          text: state.worldCountry,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountry",
            params: {
              worldCountry: state.worldCountry,
            },
          },
        },
        {
          text: state.worldCountryZone,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountryZoneTheme",
            params: {
              worldCountry: state.worldCountry,
              worldCountryZone: state.worldCountryZone,
            },
          },
        },
        {
          text: state.worldCountryZoneTheme,
          exact: true,
          disabled: true,
        }
      );
    }

    /**
     * 5e fil d'ariane
     */
    if (rootState.route.name == "worldCountryZoneThemeVote") {
      breadcrumb.push(
        {
          text: state.worldCountry,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountry",
            params: {
              worldCountry: state.worldCountry,
            },
          },
        },
        {
          text: state.worldCountryZone,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountryZoneTheme",
            params: {
              worldCountry: state.worldCountry,
              worldCountryZone: state.worldCountryZone,
            },
          },
        },
        {
          text: state.worldCountryZoneTheme,
          exact: true,
          disabled: false,
          to: {
            name: "worldCountryZoneThemeVoteList",
            params: {
              worldCountry: state.worldCountry,
              worldCountryZone: state.worldCountryZone,
              worldCountryZoneTheme: state.worldCountryZoneTheme,
            },
          },
        },
        {
          text: rootState.vote.vote.name,
          exact: true,
          disabled: true,
        }
      );
    }

    return breadcrumb;
  },
  // /**
  //  * 1er fil d'ariane : le monde
  //  */
  //     if (state.worldCountry != "") {
  //       worldArray.push(
  //         {
  //           text: "world",
  //           exact: true,
  //           disabled: false,
  //           to: {
  //             name: "world",
  //           },
  //         },
  //         {
  //           text: state.worldCountryName,
  //           exact: true,
  //           disabled: false,
  //           to: {
  //             name: "worldCountry",
  //             params: { worldCountry: state.worldCountry },
  //           },
  //         }
  //       );
  //     }
  // /**
  //  * 2e fil d'ariane : soit country, soit zones, soit themes
  //  */
  //     if (
  //       state.worldCountryTheme != "" &&
  //       state.worldCountryThemeName !== "undefined"
  //     ) {
  //       // theme
  //       wcOrWtOrWzArray.push({
  //         text: state.worldCountryThemeName,
  //         exact: true,
  //         disabled: false,
  //         to: {
  //           name: "worldCountryTheme",
  //           params: {
  //             worldCountry: state.worldCountry,
  //             worldCountryTheme: state.worldCountryTheme,
  //           },
  //         },
  //       });
  //     }
  //     if (
  //       state.worldTheme != "" &&
  //       state.worldThemeName !== "undefined"
  //     ) {
  //       // theme
  //       wcOrWtOrWzArray.push({
  //         text: state.worldThemeName,
  //         exact: true,
  //         disabled: false,
  //         to: {
  //           name: "worldTheme",
  //           params: {
  //             worldTheme: state.worldTheme,
  //           },
  //         },
  //       });
  //     }
  //     if (
  //       state.worldZone != "" &&
  //       state.worldZoneName !== "undefined"
  //     ) {
  //       // theme
  //       wcOrWtOrWzArray.push({
  //         text: (state.worldZoneThemeName == "") ? state.worldZoneTheme : state.worldZoneThemeName ,
  //         exact: true,
  //         disabled: false,
  //         to: {
  //           name: "worldZone",
  //           params: {
  //             worldZone: state.worldZone,

  //           },
  //         },
  //       });
  //     }
  //     if (
  //       state.worldCountryZone != "" &&
  //       state.worldCountryZoneName !== "undefined"
  //     ) {
  //       // theme
  //       wcOrWtOrWzArray.push({
  //         text: state.worldCountryZoneName,
  //         exact: true,
  //         disabled: false,
  //         to: {
  //           name: "worldCountryZoneTheme",
  //           params: {
  //             worldCountry: state.worldCountry,
  //             worldCountryZone: state.worldCountryZone,
  //           },
  //         },
  //       });
  //     }
  //     if (
  //       state.worldCountryZoneTheme != "" &&
  //       state.worldCountryZoneThemeName !== "undefined"
  //     ) {
  //       // liste des votes
  //       voteListArray.push({
  //         text: state.worldCountryZoneThemeName,
  //         exact: true,
  //         disabled: false,
  //         to: {
  //           name: "worldCountryZoneThemeVoteList",
  //           params: {
  //             worldCountry: state.worldCountry,
  //             worldCountryZone: state.worldCountryZone,
  //             worldCountryZoneTheme: state.worldCountryZoneTheme,
  //           },
  //         },
  //       });
  //     }
  //     // TODO : mettre un truc comme worldCountryZoneThemeVote pour ne pas mélanger avec les autres routes de votes
  //     if (
  //       // rootState.route.params.id != "" &&
  //       // hack pour maj list
  //       state.worldCountryZoneThemeVoteName != "" &&
  //       !(Object.keys(rootState.vote.vote).length === 0)
  //     ) {
  //       // le vote
  //       voteArray.push({
  //         text: rootState.vote.vote.name,
  //         exact: true,
  //         disabled: false,
  //         to: {
  //           name: "worldCountryZoneThemeVote",
  //           params: {
  //             worldCountry: state.worldCountry,
  //             worldCountryZone: state.worldCountryZone,
  //             worldCountryZoneTheme: state.worldCountryZoneTheme,
  //             worldCountryTheme: state.worldCountryTheme,
  //             id: rootState.vote.vote.id,
  //           },
  //         },
  //       });
  //     }

  //     if (
  //       typeof rootState.route.params.worldCountry != "undefined" ||
  //       typeof rootState.route.params.worldCountry != ""
  //     ) {
  //       breadcrumb = worldArray;
  //     }
  //     // if (
  //     //   typeof rootState.route.params.worldZoneTheme != "undefined" ||
  //     //   typeof rootState.route.params.worldZoneTheme != ""
  //     // ) {
  //     //   breadcrumb = worldArray;
  //     // }
  //     if (
  //       typeof rootState.route.params.worldCountryZone != "undefined" ||
  //       typeof rootState.route.params.worldCountryZone != ""
  //       ||
  //       typeof rootState.route.params.worldZone != "undefined" ||
  //       typeof rootState.route.params.worldZone != ""
  //       ||
  //       typeof rootState.route.params.worldTheme != "undefined" ||
  //       typeof rootState.route.params.worldTheme != ""
  //     ) {
  //       console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkk",worldArray.concat(wcOrWtOrWzArray));

  //       breadcrumb = worldArray.concat(wcOrWtOrWzArray);
  //     }
  //     if (
  //       typeof rootState.route.params.worldCountryTheme != "undefined" ||
  //       typeof rootState.route.params.worldCountryTheme != ""
  //     ) {
  //       breadcrumb = worldArray.concat(wcOrWtOrWzArray).concat(voteListArray);
  //     }
  //     if (
  //       typeof rootState.route.params.worldCountryZoneTheme != "undefined" ||
  //       typeof rootState.route.params.worldCountryZoneTheme != ""
  //       ||
  //       typeof rootState.route.params.worldZoneTheme != "undefined" ||
  //       typeof rootState.route.params.worldZoneTheme != ""
  //     ) {
  //       breadcrumb = worldArray.concat(wcOrWtOrWzArray).concat(voteListArray);
  //     }
  //     if (
  //       rootState.route.name == "vote" ||
  //       typeof rootState.route.params.vote != ""
  //     ) {
  //       breadcrumb = worldArray
  //         .concat(wcOrWtOrWzArray)
  //         .concat(voteListArray)
  //         .concat(voteArray);
  //     }

  // return breadcrumb;
  // },
};
