<template>
  <v-container>
    <v-breadcrumbs
      :items="breadcrumb"
      :class="{ 'pl-0': $vuetify.breakpoint.xs }"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
          :exact="item.exact"
          :to="item.to"
          v-if="item.text == 'world'"
        >
          <v-btn fab x-small width="12" height="12" light>
            <svg-icon type="mdi" :path="earthPath" size="12"></svg-icon>
          </v-btn>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
          :exact="item.exact"
          :to="item.to"
          v-else
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <svg-icon type="mdi" :path="chevronRightPath" size="12"></svg-icon>
      </template>
    </v-breadcrumbs>
  </v-container>
</template>

<script>
import Vuex from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiEarth, mdiChevronRight } from "@mdi/js";

export default {
  name: "Breadcrumb",
  components: {
    SvgIcon,
  },
  data: () => ({
    chevronRightPath: mdiChevronRight,
    earthPath: mdiEarth,
  }),
  computed: {
    ...Vuex.mapGetters("list", ["breadcrumb"]),
  },
};
</script>

<style>
.v-breadcrumbs__divider {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.v-breadcrumbs li {
  font-size: 10px !important;
}
.v-breadcrumbs li a {
  color: rgb(255, 255, 255);
}
</style>
