// import { App } from "../app";
// import "firebase/auth";
// import { auth } from "firebase/app";

// const Auth = App.auth();

// let EmailAuthProviderCredential = (email, pwd) => {
//   return App.auth.EmailAuthProvider.credential(email, pwd);
// };

// // not used : const PersistenceSESSION = firebase.auth.Auth.Persistence.SESSION;
// const PersistenceLOCAL = auth.Auth.Persistence.LOCAL;

// export {
//   Auth,
//   PersistenceLOCAL,
//   // PersistenceSESSION,
//   EmailAuthProviderCredential,
// };
import { App } from "../app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const Auth = getAuth(App);

if (window.location.hostname === "localhost" || window.location.hostname === "192.168.0.49") {
  connectAuthEmulator(Auth, "http://192.168.0.49:9099"); 
}
if (window.location.hostname === "localhost" || window.location.hostname === "192.168.0.4") {
  connectAuthEmulator(Auth, "http://192.168.0.4:9099");
}
if (window.location.hostname === "localhost" || window.location.hostname === "192.168.0.5") {
	connectAuthEmulator(Auth, "http://192.168.0.5:9099");
  }
let EmailAuthProviderCredential = (email, pwd) => {
  return App.auth.EmailAuthProvider.credential(email, pwd);
};

// not used : const PersistenceSESSION = firebase.auth.Auth.Persistence.SESSION;
// const PersistenceLOCAL = auth.Auth.Persistence.LOCAL;

export {
  Auth,
  // PersistenceLOCAL,
  // PersistenceSESSION,
  EmailAuthProviderCredential,
};
