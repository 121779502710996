<template>
  <v-snackbar
    v-model="snackbar.show"
    color=""
    :top="true"
    :bottom="false"
    :left="false"
    :right="false"
    :multi-line="true"
    :timeout="1500"
    :vertical="false"
  >
    <v-layout align-center>
      <v-row>
        <v-col cols="1">
          <v-avatar size="24">
            <img alt="Bob" :src="require('@/assets/smiley24.png')" />
          </v-avatar>
        </v-col>
        <v-col cols="undefined" class="justify-center align-center">
          <v-row class="justify-center align-center">
            <strong class="snackBarTitle">{{ snackbar.title }}</strong>
          </v-row>
          <v-row
            class="justify-center align-center"
            v-if="snackbar.text !== ''"
            >{{ snackbar.text }}</v-row
          >
          <v-row
            class="justify-center align-center"
            v-if="snackbar.text !== ''"
          >
            <v-btn
              x-small
              text
              @click="
                HIDE_SNACKBAR();
                goBack();
              "
            >
              Retour
            </v-btn></v-row
          >
        </v-col>
        <v-col cols="1" @click="HIDE_SNACKBAR()">
          <!-- <v-icon x-small @click="HIDE_SNACKBAR()">mdi-close</v-icon> -->
          <svg-icon
            x-small
            type="mdi"
            :path="closePath"
            size="16"
            right
          ></svg-icon>
        </v-col>
      </v-row>
    </v-layout>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose } from "@mdi/js";

export default {
  components: {
    SvgIcon,
  },
  data: function () {
    return {
      closePath: mdiClose,
    };
  },
  name: "snackbar",
  computed: mapState(["snackbar"]),
  methods: {
    ...mapMutations(["HIDE_SNACKBAR"]),
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
};
</script>
