import store from "../store";

const Account = () =>
  import(/* webpackChunkName: "account" */ "@/component/user/account/Account");
const MyCard = () =>
  import(/* webpackChunkName: "account" */ "@/component/user/account/MyCard");
const MyVote = () =>
  import(/* webpackChunkName: "account" */ "@/component/user/account/MyVote");
// const MyPin = () =>
//   import(/* webpackChunkName: "account" */ "@/component/user/account/MyPin");
const ComingSoon = () =>
  import(/* webpackChunkName: "account" */ "@/component/ui/ComingSoon");

export default [
  {
    path: "/",
    component: Account,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "ma-carte-d-electeur",
        name: "MyAccount",
        component: MyCard,
      },
      {
        path: "mes-votes",
        name: "MyVote",
        component: MyVote,
      },
      {
        path: "mes-votes-epingles",
        name: "MyVotePinned",
        component: ComingSoon,
        beforeEnter: (to, from, next) => {
          store.commit("showBackBtn", true);
          next();
        },
      },
      {
        path: "mes-listes-epingles",
        name: "MyListPinned",        
        component: ComingSoon,
        beforeEnter: (to, from, next) => {
          store.commit("showBackBtn", true);
          next();
        },
      },
      {
        path: "mes-votes-masques",
        name: "MyVoteHidden",
        component: ComingSoon,
        beforeEnter: (to, from, next) => {
          store.commit("showBackBtn", true);
          next();
        },
      },
      {
        path: "mes-listes-masques",
        name: "MyListHidden",        
        component: ComingSoon,
        beforeEnter: (to, from, next) => {
          store.commit("showBackBtn", true);
          next();
        },
      },      
    ],
  },
];
