export default {
  goback: false,
  breadcrumb: [],
  snackbar: {
    show: false,
    title: "",
    text: "",
  },
  faq: false,
  loading: false,
  showSearchText: false,
};
