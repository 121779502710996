export default {
  currentUser: {},
  userProfile: {},
  userVotes: [],
  userPinDetail: [],
  pin: [],
  rankUp: [],
  rankDown: [],
  isAuthenticatedUser: false,
};
