// import { vuexfireMutations } from "vuexfire";

import { loadBundle } from "firebase/firestore";

export default {
  showBackBtn(state, val) {
    state.goback = val;
  },
  SHOW_SNACKBAR(state, payload) {
    state.snackbar.show = true;
    state.snackbar.text = payload.text;
    state.snackbar.title = payload.title;
  },
  HIDE_SNACKBAR(state) {
    state.snackbar.show = false;
  },
  openFaq(state, val) {
    state.faq = val;
  },
  loading(state, val) {
    state.loading = val
  },
  showSearchText(state, val) {
    state.showSearchText = val;
  },
  
  // ...vuexfireMutations,
};
