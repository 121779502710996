import store from "../store";

/**
 *
 * world :                        liste des pays du monde                                 /
 * WorldCountryAndZoneAndTheme    liste des zones du monde                                /
 *                                liste des themes du monde                               /
 *
 * worldThemeVoteList             liste des votes d'un theme du monde                     /:worldTheme
 * worldThemeVote                 vote d'un item d'un theme du monde                      /:worldTheme/:id
 *
 * worldZone                      liste des themes d'une zone du monde                    /:worldZone
 * worldZoneThemeList             liste des votes d'un theme d'une zone du monde          /:worldZone/:worldTheme
 * worldZonetThemeVote            vote d'un item d'un theme d'une zone du monde           /:worldZone/:worldTheme/:id
 *
 *
 * MAINTENIR COUNTRY POUR DIFFERENCIER ZONE (GRANDE ZONE, CONTINENT, SOUS-CONTINENT...) ET COUNTRY
 * worldCountry                   liste des zones du pays                                 /:worldCountry
 *                                liste des themes du pays                                /:worldCountry
 *
 * worldCountryThemeVoteList      liste des votes d'un theme du pays                      /:worldCountry/:worldTheme
 * worldCountryThemeVote          vote d'un item d'un theme du pays                       /:worldCountryTheme/:id
 *
 * worldCountryZoneTheme          liste des themes d'une zone d'un pays                   /:worldCountry/:worldCountryZone
 * worldCountry                  liste des sous-zones (récursif)                          /:country
 *
 * worldCountryZoneThemeVoteList  liste des votes d'un theme d'une zone d'un pays         /:worldCountry/:worldCountryZone/:worldCountryZoneTheme
 *
 * worldCountry-zone-theme-vote  vote d'un item d'un theme d'une zone d'un pays          /:country/:zone/:theme/:id
 */


// /fr : region, département, communes, cc, canton...
const WorldCountryZoneList = () =>
  // import(/* webpackChunkName: "zone" */ "@/component/list/WorldCountryZone");
  import(
    /* webpackChunkName: "zone" */ "@/component/list/world/country/zone/Home"
  );

const WorldCountryHome = () =>
  // import(/* webpackChunkName: "zone" */ "@/component/list/WorldCountryZone");
  import(/* webpackChunkName: "zone" */ "@/component/list/world/country/Home");


// /fr
const WorldCountryThemeList = () =>
  // import(/* webpackChunkName: "theme" */ "@/component/list/WorldCountryTheme");
  // import(/* webpackChunkName: "theme" */ "@/component/list/world/country/theme/VoteList");
  import(
    /* webpackChunkName: "theme" */ "@/component/list/world/country/theme/Home"
  );

// /fr/dourdan
const WorldCountryZoneThemeList = () =>
  // import(/* webpackChunkName: "theme" */ "@/component/list/WorldCountryZoneTheme");
  // TODO à transformer partout
  import(
    /* webpackChunkName: "theme" */ "@/component/list/world/country/zone/theme/Home"
  );

/**
 * liste des votes
     // WorldTheme : /war, /climat, /peace ...
 // WorldCountryTheme : /fr/presidentielles-2022
 // WorldCountryZoneTheme : /fr/dourdan/municipales-2020
 // WorldZoneTheme : /ue/integration-de-pays
 */
const VoteList = () =>
  import(
    /* webpackChunkName: "voteList" */ "@/component/vote/voteList/VoteList"
  );

export default [
  /**
   * liste des thèmes d'une zone d'un pays
   */
  {
    path: "/:worldCountry/:worldCountryZone/themes",
    meta: {
      requiresAuth: false,
    },
    name: "worldCountryZoneTheme",
    component: WorldCountryZoneThemeList,

    beforeEnter: (to, from, next) => {
      if (to.fullPath.includes("admin")) {
        next(false);
      }

      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");
      store.commit("vote/resetLastId");

      if (store.state.list.worldCountry == "") {
        store.commit("list/setWorldCountry", to.params.worldCountry);
        store.commit("list/setWorldCountryName", to.params.worldCountry);
      }

      if (store.state.list.worldCountryZone == "") {
        store.commit("list/setWorldCountryZone", to.params.worldCountryZone);
        store.commit(
          "list/setWorldCountryZoneName",
          to.params.worldCountryZone
        );
      }

      // if (store.state.list.worldCountryZoneTheme == "") {
      //   console.log("es tu la ? 2", to.params);

      //   store.commit(
      //     "list/setWorldCountryZoneTheme",
      //     to.params.worldCountryZoneTheme
      //   );
      //   store.commit(
      //     "list/setWorldCountryZoneThemeName",
      //     to.params.worldCountryZoneTheme
      //   );
      // }
      next();
    },
  },
  /**
   * liste des votes d'un thème d'une zone d'un pays
   */
  {
    path: "/:worldCountry/:worldCountryZone/:worldCountryZoneTheme/votes",
    meta: {
      requiresAuth: false,
    },
    name: "worldCountryZoneThemeVoteList",
    component: VoteList,
    beforeEnter: (to, from, next) => {     
      if (to.fullPath.includes("admin")) {
        next(false);
      }
      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");
      store.commit("vote/resetLastId");
      store.commit("list/resetForWorldCountryZoneTheme");
      if (store.state.list.worldCountry == "") {
        store.commit("list/setWorldCountry", to.params.worldCountry);
        store.commit("list/setWorldCountryName", to.params.worldCountry);
      }

      if (store.state.list.worldCountryZone == "") {
        store.commit("list/setWorldCountryZone", to.params.worldCountryZone);
        store.commit(
          "list/setWorldCountryZoneName",
          to.params.worldCountryZone
        );
      }
      if (store.state.list.worldCountryZoneTheme == "") {
        store.commit(
          "list/setWorldCountryZoneTheme",
          to.params.worldCountryZoneTheme
        );
        store.commit(
          "list/setWorldCountryZoneThemeName",
          to.params.worldCountryThemeZone
        );
      }

      if (store.state.list.worldCountryZone == "") {
        store.commit("list/setWorldCountryZone", to.params.worldCountryZone);
        store.commit(
          "list/setWorldCountryZoneName",
          to.params.worldCountryZone
        );
      }

      if (store.state.list.worldCountryZoneTheme == "") {
        store.commit(
          "list/setWorldCountryZoneTheme",
          to.params.worldCountryZoneTheme
        );
        store.commit(
          "list/setWorldCountryZoneThemeName",
          to.params.worldCountryZoneTheme
        );
      }

      next();
    },
  },
  /**************************************************************************
  Liste des zones d'un pays
  */
  {
    path: "/:worldCountry/zones",
    // path: "/:worldCountry/:worldCountryZone",
    name: "worldCountryZone",
    meta: {
      requiresAuth: false,
    },
    component: WorldCountryZoneList,
    beforeEnter: (to, from, next) => {
      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");
      store.commit("vote/resetLastId");

      if (store.state.list.worldCountry == "") {
        store.commit("list/setWorldCountry", to.params.worldCountry);
        store.commit("list/setWorldCountryName", to.params.worldCountry);
      }

      next();
    },
  },

  /**************************************************************************
   Liste des thèmes d'un pays
   */
  {
    path: "/:worldCountry/themes",
    name: "worldCountryTheme",
    meta: {
      requiresAuth: false,
    },
    component: WorldCountryThemeList,
    beforeEnter: (to, from, next) => {
      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");
      store.commit("vote/resetLastId");

      if (store.state.list.worldCountry == "") {
        store.commit("list/setWorldCountry", to.params.worldCountry);
        store.commit("list/setWorldCountryName", to.params.worldCountry);
      }

      next();
    },
  },
  /**************************************************************************
  Liste des votes d'un thème d'un pays
  */
  {
    path: "/:worldCountry/theme/:worldCountryTheme",
    name: "worldCountryThemeVoteList",
    meta: {
      requiresAuth: false,
    },
    component: VoteList, 
    // component: WorldCountryThemeList, // TEST
    beforeEnter: (to, from, next) => {
      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");
      store.commit("vote/resetLastId");
      store.commit("list/resetForWorldCountryTheme");

      if (store.state.list.worldCountry == "") {
        store.commit("list/setWorldCountry", to.params.worldCountry);
        store.commit("list/setWorldCountryName", to.params.worldCountry);
      }

      if (store.state.list.worldCountryTheme == "") {
        store.commit("list/setWorldCountryTheme", to.params.worldCountryTheme);
        store.commit(
          "list/setWorldCountryThemeName",
          to.params.worldCountryTheme
        );        
      }

      next();
    },
  },

 /**************************************************************************
  Résultats d'une liste des votes d'un thème d'un pays
  */
  {
    path: "/:worldCountry/theme/:worldCountryTheme/resultats",
    name: "worldCountryThemeVoteListResult",
    meta: {
      requiresAuth: false,
    },
    component: VoteList, 
    // component: WorldCountryThemeList, // TEST
    beforeEnter: (to, from, next) => {
      // on peut enlever pour garder pour le un retour de la page précédente
      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");
      store.commit("vote/resetLastId");
      store.commit("list/resetForWorldCountryTheme");

      if (store.state.list.worldCountry == "") {
        store.commit("list/setWorldCountry", to.params.worldCountry);
        store.commit("list/setWorldCountryName", to.params.worldCountry);
      }

      if (store.state.list.worldCountryTheme == "") {
        store.commit("list/setWorldCountryTheme", to.params.worldCountryTheme);
        store.commit(
          "list/setWorldCountryThemeName",
          to.params.worldCountryTheme
        );        
      }

      next();
    },
  },

  /**
     Liste des votes d'un thème d'une zone du pays 
     */

  {
    path: "/zone/:worldZone/theme/:worldZoneTheme",
    name: "worldZoneThemeVoteList",
    component: VoteList,
    beforeEnter: (to, from, next) => {
      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");
      store.commit("vote/resetLastId");
      store.commit("list/resetForWorldZoneTheme");
      if (store.state.list.worldZone == "") {
        store.commit("list/setWorldZone", to.params.worldZone);
        store.commit("list/setWorldZoneName", to.params.worldZone);
      }
      if (store.state.list.worldZoneTheme == "") {
        store.commit("list/setWorldZoneTheme", to.params.worldZoneTheme);
        store.commit("list/setWorldZoneThemeName", to.params.worldZoneTheme);
      }
      next();
    },
  },
  /**
   * Liste des zones d'un pays /fr  (département, région, commune, canton, cc)
   */
  {
    path: "/:worldCountry",
    meta: {
      requiresAuth: false,
    },
    name: "worldCountry",
    component: WorldCountryHome,
    beforeEnter: (to, from, next) => {
      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");

      store.commit("list/resetForWorldTheme");
      store.commit("list/resetForWorldZone");

      if (store.state.list.worldCountry == "") {
        store.commit("list/setWorldCountry", to.params.worldCountry);
        store.commit("list/setWorldCountryName", to.params.worldCountry);
      }
      // hack pour action getList
      // store.commit("list/changeWorldChoice", "country");

      next();
    },
  },
];
