import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import web from "./web";
import auth from "./auth";
import vote from "./vote";
import voteDynamic from "./voteDynamic";
import list from "./list";
import listDynamic from "./listDynamic";
import account from "./account";
import { onAuthStateChanged } from "firebase/auth";
let A;
if (process.env.VUE_APP_MODE == "production") {
  //import { Auth } from '@/firebase/production/auth';
  A = require("@/firebase/production/auth.js");
} else {
  A = require("@/firebase/development/auth.js");
}
const Auth = A.Auth;
Vue.use(VueRouter);

const routes = [
  ...list,
  ...auth,
  ...account,
  ...vote,
  ...listDynamic,
  ...voteDynamic,
  ...web,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  onAuthStateChanged(Auth, (user) => {
    if (user) {
      if (store.state.user.isAuthenticatedUser == false) {
        store.dispatch("user/setAuthenticatedUser", { authenticated: true });
        if (store.state.user.currentUser.uid == undefined) {
          store.commit("user/SET_CURRENT_USER", {
            displayName: user.displayName,
            uid: user.uid,
            metadata: user.metadata,
          });
          store.dispatch("user/FETCH_USER_PROFILE", user);
          store.dispatch("user/FETCH_USER_VOTES", user);
        }
      }
      next();
    } else {
      if (requiresAuth) {
        if(to.name !== undefined) sessionStorage.setItem("redirectPath", to.path);
        next({
          name: "Login",
        });
      }
      else next();        
    }
  });
});

// account and login
// router.beforeEach(async (to, from, next) => {
//   const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
//   console.log("ok");
//   onAuthStateChanged(Auth, (user) => {
//     console.log("Auth.currentUser", Auth.currentUser, requiresAuth)
//   })

//   if (from.name === null || from.name == "Login" || from.name == "SignUp") {
//     onAuthStateChanged(Auth, (user) => {
//       if (user) {
//         if (store.state.user.isAuthenticatedUser == false)
//           store.dispatch("user/setAuthenticatedUser", { authenticated: true });
//       } else {
//         if (store.state.user.isAuthenticatedUser == true)
//           store.dispatch("user/setAuthenticatedUser", { authenticated: false });
//       }

//       if (
//         requiresAuth &&
//         !user &&
//         (to.name !== "Login" || to.name !== "SignUp")
//       ) {
//         sessionStorage.setItem("redirectPath", to.path);
//         next({
//           name: "Login",
//         });
//       } else if ((requiresAuth && user) || (to.name == undefined && user)) {
//         if (store.state.user.currentUser.uid == undefined) {
//           store.commit("user/SET_CURRENT_USER", {
//             displayName: user.displayName,
//             uid: user.uid,
//             metadata: user.metadata,
//           });
//           store.dispatch("user/FETCH_USER_PROFILE", user);
//           store.dispatch("user/FETCH_USER_VOTES", user);
//         }
//         next();
//       } else {
//         next();
//       }
//     });
//   } else next();
// });

export default router;
