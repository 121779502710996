import state from "./state";

export default {
  emptyElectedCalculation(state) {
    if (state.electedMajorite.length > 0) state.electedMajorite = [];
    if (state.electedEcart.length > 0) state.electedEcart = [];
    if (state.electedPour.length > 0) state.electedPour = [];
    state.electedCriteria.pour = false;
    state.electedCriteria.majorite = false;
    state.electedCriteria.ecart = false;
    state.allVotesLoaded = false;
    state.electedCriteriaButtonActivated.pour = "white";
    state.electedCriteriaButtonActivated.majorite = "white";
    state.electedCriteriaButtonActivated.ecart = "white";
  },
  electedCriteriaButtonActivated(state, val) {
    if (val == "majorite") {
      state.electedCriteriaButtonActivated.majorite =
        state.electedCriteriaButtonActivated.majorite == "white"
          ? "blue"
          : "white";
      if (state.electedCriteriaButtonActivated.majorite == "blue") {
        state.electedCriteriaButtonActivated.pour = "white";
        state.electedCriteriaButtonActivated.ecart = "white";
      } else {
		// state["electedCriteria"] = {
		// 	pour: false,
		// 	majorite: false,
		// 	ecart: false,
		// 	}
        // state.electedCriteria.pour = false;
        state.electedCriteria.majorite = false;
        // state.electedCriteria.ecart = false;
      }
    } else if (val == "pour") {
      state.electedCriteriaButtonActivated.pour =
        state.electedCriteriaButtonActivated.pour == "white"
          ? "orange"
          : "white";
      if (state.electedCriteriaButtonActivated.pour == "orange") {
        state.electedCriteriaButtonActivated.majorite = "white";
        state.electedCriteriaButtonActivated.ecart = "white";
      } else {
        state.electedCriteria.pour = false;
        state.electedCriteria.majorite = false;
        state.electedCriteria.ecart = false;
      }
    } else if (val == "ecart") {
      state.electedCriteriaButtonActivated.ecart =
        state.electedCriteriaButtonActivated.ecart == "white"
          ? "purple"
          : "white";
      if (state.electedCriteriaButtonActivated.ecart == "purple") {
        state.electedCriteriaButtonActivated.pour = "white";
        state.electedCriteriaButtonActivated.majorite = "white";
      } else {
        state.electedCriteria.pour = false;
        state.electedCriteria.majorite = false;
        state.electedCriteria.ecart = false;
      }
    }
  },
  updateElectedCalculation(state, data) {
    state[data.criteria] = data.voteIdArray;
    // if (!Array.prototype.indexOf) {
    //   Array.prototype.indexOf = function (str) {
    //     var i;
    //     for (i = 0; i < this.length; i++) if (this[i] == str) return i;
    //     return -1;
    //   };
    // }

    // state.votes = state.votes.sort(function (x, y) {
    //   return data.voteIdArray.indexOf(x.id) - data.voteIdArray.indexOf(y.id);
    // });
  },
  updateElectedCriteria(state, val) {
    state.electedCriteria.ecart = val.electedEcart;
    state.electedCriteria.majorite = val.electedMajorite;
    state.electedCriteria.pour = val.electedPour;
  },
  allVotesLoaded(state, val) {
    state.allVotesLoaded = val;
  },
  setVoteCache(state, voteList) {
    state.voteCache[voteList.id] = {};
    state.voteCache[voteList.id].votes = voteList.votes;
    state.voteCache[voteList.id].electedEcart = voteList.electedEcart;
    state.voteCache[voteList.id].electedMajorite = voteList.electedMajorite;
    state.voteCache[voteList.id].electedPour = voteList.electedPour;
  },
  myVote(state, val) {
    state.myVote = val;
  },
  displayAllVotes(state, val) {
    state.displayAllVotes = val;
  },
  feedList(state, list) {
    if (list.typeList == "vote") {
      if (state.list.length == 0) state.list = list.list;
      else state.list = state.list.concat(list.list);
    }
    if (list.typeList == "country") {
      if (state.listCountry.length == 0) state.listCountry = list.list;
      else state.listCountry = state.listCountry.concat(list.list);
    }
    if (list.typeList == "theme") {
      if (state.listTheme.length == 0 || state.subThemeFlag) {
        state.listTheme = list.list;
        // state.subThemeFlag = false
      } 
      else state.listTheme = state.listTheme.concat(list.list);
    }
    if (list.typeList == "zone") {
      // comparer aussi si la liste n'est pas une sous zone
      if (state.listZone.length == 0 || state.subZoneFlag) {
        state.listZone = list.list;
        // state.subZoneFlag = false
      }
      else state.listZone = state.listZone.concat(list.list);
    }
  },
  setWorldCountryZoneType(state, zoneTypes) {
    state.worldCountryZoneType = Object.keys(zoneTypes);
  },
  resetList(state, val) {
    if (val != "") state[val] = [];
    else {
      state.list = [];
      state.listCountry = [];
      state.listZone = [];
      state.listTheme = [];
    }
  },
  setLastListId(state, val) {
    if (val.typeList == "vote") state.lastListId = val.lastListId;
    if (val.typeList == "country") state.lastListCountryId = val.lastListId;
    if (val.typeList == "theme") state.lastListThemeId = val.lastListId;
    if (val.typeList == "zone") state.lastListZoneId = val.lastListId;
  },
  resetLastListId(state) {
    state.lastListId = "";
    state.lastListCountryId = "";
    state.lastListZoneId = "";
    state.lastListThemeId = "";
  },
  feedVotes(state, votes) {
    // hack pour maj list
    if (votes.length == 0) state.votes = [];	
    else state.votes = state.votes.concat(votes);
    // state.votes = state.votes.concat(votes);
  },
  feedVotesAllReload(state, votes) {
    // hack pour maj list
    if (votes.length == 0) state.votes = [];	
    else state.votes = votes;
  },
  setQueryLimit(state, val) {
    state.queryLimit = val;
  },
  setLastId(state, val) {
    state.lastId = val;
  },
  electedCriteriaReset(state, val) {
    state.electedCriteriaReset = val;
  },
  resetLastId(state) {
    state.lastId = "";
  },
  activeVote(state, val) {
    state.vote = val;
  },
  setCountryName(state, val) {
    state.countryName = val;
  },
  setZoneName(state, val) {
    state.zoneName = val;
  },
  setThemeName(state, val) {
    state.themeName = val;
  },
  setPrevPathList(state, val) {
    if (val.typeList == "country") state.prevCountryPathList = val.prevPathList;
    if (val.typeList == "theme") state.prevThemePathList = val.prevPathList;
    if (val.typeList == "zone") state.prevZonePathList = val.prevPathList;
  },
  setSubThemeFlag(state, val) {
    state.subThemeFlag = val
  },
  setSubZoneFlag(state, val) {
    state.subZoneFlag = val
  },
  setListParam(state, obj) {
    const up = Object.assign({}, state.listParam);
    up[obj.key] = obj.val;
    state.listParam = up;
  },
};
