import store from "../store";

const Vote = () =>
  import(/* webpackChunkName: "vote" */ "@/component/vote/voteItem/Vote");

export default [  
  {
    path: "/theme/:worldTheme/:id",
    meta: {
      requiresAuth: false,
    },
    name: "worldThemeVote",
    component: Vote,
    beforeEnter: async (to, from, next) => {
      if (typeof store.state.vote.vote.id === "undefined") {
        let path =
          to.params.worldTheme !== "undefined"
            ? "/theme/" + to.params.worldTheme + "/voteList"
            : "";
        await store.dispatch("vote/getVote", {
          path: path,
          id: to.params.id,
        });

        if (store.state.list.worldTheme == "") {
          store.commit("list/setWorldTheme", to.params.worldTheme);
          store.commit("list/setWorldThemeName", to.params.worldTheme);
        }
        next();
      } else {
        next();
      }
    },
  },
  {
    path: "/zone/:worldZone/theme/:worldZoneTheme/:id",
    name: "worldZoneThemeVote",
    component: Vote,
    beforeEnter: async (to, from, next) => {
      if (typeof store.state.vote.vote.id === "undefined") {
        let path =
          to.params.worldZoneTheme !== "undefined"
            ? "/theme/" + to.params.worldZoneTheme + "/voteList"
            : "";
        await store.dispatch("vote/getVote", {
          path: path,
          id: to.params.id,
        });

        if (store.state.list.worldZoneTheme == "") {
          store.commit("list/setWorldZoneTheme", to.params.worldZoneTheme);
          store.commit("list/setWorldZoneThemeName", to.params.worldZoneTheme);
        }
        next();
      } else {
        next();
      }
    },
  },
];
