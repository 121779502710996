<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app clipped touchless>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"
              ><a href="/" style="text-decoration: none; color: #ffffff">
                Demokrat.io
              </a></v-list-item-title
            >
            <v-list-item-subtitle> Décide de vivre </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mx-2"></v-divider>
        <v-list-item
          class="pt-2"
          href="https://salvador-ribeiro.web.app"
          target="_blank"
        >
          <v-list-item-icon>
            <svg-icon type="mdi" :path="votePath"></svg-icon>
          </v-list-item-icon>
          <v-list-item-title>Législatives 2024</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item-group v-if="isAuthenticatedUser">
          <v-list-item @click="goTo('MyAccount')">
            <v-list-item-action>
              <v-avatar size="24">
                <img alt="Bob" :src="require('@/assets/smiley24.png')" />
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                >{{ currentUser.displayName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="goTo('MyVote')">
            <v-list-item-action>
              <svg-icon type="mdi" :path="votePath"></svg-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Mes votes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list>
            <v-list-group no-action dark color="grey lighten-5">
              <template v-slot:activator>
                <v-list-item-action>
                  <svg-icon type="mdi" :path="pinPath"></svg-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Epinglés</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item @click="goTo('MyVotePinned')">
                <v-list-item-action>
                  <svg-icon type="mdi" :path="votePath"></svg-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Votes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="goTo('MyListPinned')">
                <v-list-item-action>
                  <svg-icon
                    type="mdi"
                    :path="formatListBulletedPath"
                  ></svg-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Listes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-list>
            <v-list-group no-action dark color="grey lighten-5">
              <template v-slot:activator>
                <v-list-item-action>
                  <svg-icon type="mdi" :path="eyeOffPath"></svg-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Masqués</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item @click="goTo('MyVoteHidden')">
                <v-list-item-action>
                  <svg-icon type="mdi" :path="votePath"></svg-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Mes votes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="goTo('MyListHidden')">
                <v-list-item-action>
                  <svg-icon type="mdi" :path="pinPath"></svg-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Mes épinglés</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-divider class="mx-2"></v-divider>
          <v-list-item @click.stop="dialog = true" class="pt-2">
            <v-list-item-icon>
              <svg-icon type="mdi" :path="logoutPath"></svg-icon>
            </v-list-item-icon>
            <v-list-item-title>Ranger sa carte d'électeur</v-list-item-title>
            <v-dialog v-if="dialog" v-model="dialog" max-width="290">
              <v-card>
                <v-card-title class="text-h5"> Confirmation </v-card-title>
                <v-card-text>
                  Etes-vous sûr de vouloir ranger votre carte d'électeur
                  ?</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click.stop="dialog = false"> Non </v-btn>
                  <v-btn text @click="logout"> Oui </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-else>
          <v-list-item @click="goTo('Login')">
            <v-list-item-icon>
              <svg-icon type="mdi" :path="loginPath"></svg-icon>
            </v-list-item-icon>
            <v-list-item-title>Présenter sa carte d'électeur</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goTo('SignUp')">
            <v-list-item-icon>
              <svg-icon type="mdi" :path="cardPlusPath"></svg-icon>
            </v-list-item-icon>
            <v-list-item-title>Créer une carte d'électeur</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- <NavigationDrawer  /> -->
    <!-- <AppBar /> -->
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon v-if="goback" @click="goBackButton">
        <svg-icon type="mdi" :path="arrowLeftPath"></svg-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-else @click.stop="drawer = !drawer">
        <!-- <v-app-bar-nav-icon v-else /> -->
        <svg-icon type="mdi" :path="menuPath"></svg-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title dense class="pl-0">
        <!-- {{ JSON.stringify($route, null, 2) }} -->
        <Breadcrumb />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <div v-if="Object.entries(currentUser).length !== 0">{{currentUser}}</div> -->
      <v-slide-x-reverse-transition>
        <v-row
          no-gutters
          v-show="showSearchText"
          id="search-text"
          align="start"
          justify="center"
        >
          <v-text-field
            autofocus
            hide-details="true"
            ref="search"
            type="search"
            label=""
            solo
            v-model.lazy="searchText"
          ></v-text-field>
        </v-row>
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition>
        <v-btn
          v-show="showSearchText"
          tile
          text
          icon
          small
          @click="searchClose()"
        >
          <svg-icon type="mdi" :path="searchClosePath" size="16"></svg-icon>
        </v-btn>
      </v-slide-x-reverse-transition>
      <v-btn tile text icon small @click="search()">
        <svg-icon type="mdi" :path="searchPath" size="16"></svg-icon>
      </v-btn>
      <v-btn tile text icon small @click="faq()">
        <svg-icon type="mdi" :path="questionPath" size="16"></svg-icon>
      </v-btn>
      <v-btn tile text icon color="yellow" small @click="goTo('hot')">
        <svg-icon type="mdi" :path="firePath" size="16"></svg-icon>
      </v-btn>
      <v-btn tile text icon color="yellow" small @click="goTo('mostPinned')">
        <svg-icon type="mdi" :path="pinPath" size="16"></svg-icon>
        <!-- <v-icon small><svg-icon type="mdi" :path="path"></svg-icon></v-icon> -->
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-card
          max-width="600"
          class="mx-auto mt-2 mb-2 d-flex align-stretch flex-column"
          ><v-row no-gutters align="center" justify="center" class="py-1">
            <h6 style="word-break: normal">
              <a href="https://salvador-ribeiro.web.app"
              target="_blank">Demokratio nouvelle version dans quelques jours... => législatives 2024</a>
            </h6>
          </v-row></v-card
        >

        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>
    <v-footer app></v-footer>
    <Snackbar />
    <Faq />
  </v-app>
</template>

<script>
import Snackbar from "@/component/ui/Snackbar";
import Faq from "@/component/ui/Faq";
import Breadcrumb from "@/component/ui/Breadcrumb";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiVote,
  mdiFire,
  mdiFrequentlyAskedQuestions,
  mdiArrowLeft,
  mdiCardPlus,
  mdiLogout,
  mdiPin,
  mdiMenuDown,
  mdiFormatListBulleted,
  mdiEyeOff,
  mdiLogin,
  mdiMenu,
  mdiMagnify,
  mdiClose,
} from "@mdi/js";

import Vuex from "vuex";

export default {
  name: "App",
  components: {
    Snackbar,
    Breadcrumb,
    SvgIcon,
    Faq,
  },
  data: () => ({
    drawer: null,
    dialog: false,
    votePath: mdiVote,
    firePath: mdiFire,
    arrowLeftPath: mdiArrowLeft,
    eyeOffPath: mdiEyeOff,
    cardPlusPath: mdiCardPlus,
    loginPath: mdiLogin,
    logoutPath: mdiLogout,
    questionPath: mdiFrequentlyAskedQuestions,
    pinPath: mdiPin,
    menuDownPath: mdiMenuDown,
    formatListBulletedPath: mdiFormatListBulleted,
    menuPath: mdiMenu,
    searchPath: mdiMagnify,
    searchText: "recherche bientôt fonctionnelle ;)",
    searchClosePath: mdiClose,
  }),
  // props: {
  //   source: String,
  // },
  props: ["infiniteLoading"],
  methods: {
    async search() {
      if (!this.showSearchText) {
        this.$store.commit("showSearchText", true);
      } else {
        if (this.searchText !== "") {
          this.infiniteLoading.status = 1;

          await this.infiniteLoading.$emit(
            "infinite",
            this.infiniteLoading.stateChanger
          );
        }
      }
    },
    searchClose() {
      if (this.showSearchText) {
        this.searchText = "";
        this.$store.commit("showSearchText", false);
      }
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.dialog = false;
    },
    goTo(routeName) {
      if (routeName == "hot" || routeName == "mostPinned")
        this.$store.dispatch("backBtn", true);
      if (this.$route.name != routeName) this.$router.push({ name: routeName });
    },
    faq() {
      this.$store.commit("openFaq", true);
    },
    filter: function (filterz) {
      alert(filterz);
    },
    goBackButton() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
      this.$store.dispatch("backBtn", false);
    },
  },
  computed: {
    ...Vuex.mapState("user", ["currentUser", "isAuthenticatedUser"]),
    ...Vuex.mapState(["goback", "showSearchText"]),
  },
  created() {
    this.$vuetify.theme.dark = "true";
  },
};
</script>
<style>
/* .v-input__slot {
  height: 20px;
}
.v-text-field.v-text-field--solo .v-input__control {
  max-height: 20px;
} */
</style>
