import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  limit,
  orderBy,
  where,
  startAfter,
} from "firebase/firestore";

if (process.env.VUE_APP_MODE == "production") {
  require("@/firebase/production/db");
} else require("@/firebase/development/db");
let D;
if (process.env.VUE_APP_MODE == "production") {
  D = require("@/firebase/production/db.js");
} else {
  D = require("@/firebase/development/db.js");
}
const DB = D.DB;
const FieldValue = D.FieldValue;
import { unwrapFirestoreDoc, setToFirestore } from "firewings";

export default {
  getWorldCountryThemeVoteListLength: async (
    { commit, dispatch, state, rootState },
    payload
  ) => {
    // let theme = await DB.collection(payload.path).doc(payload.id).get();
    let theme = await getDoc(
      doc(
        DB,
        "/country/" + rootState.route.params.worldCountry + "/theme",
        rootState.route.params.worldCountryTheme
      )
    );
    const themez = unwrapFirestoreDoc(theme);
    // console.log("thmeez", themez);
    commit("setWorldCountryThemeVoteListLength", themez.voteListLength);
  },
  getHotList: async ({ commit, dispatch, state, rootState }, payload) => {
    let q;
    let queryLimit = 20;
    q = query(
      collection(DB, "/shortcutList"),
      where("typeList", "==", "hot"),
      where("visible", "==", true),
      orderBy("rank", "asc")
      // limit(queryLimit)
    );

    let list = await getDocs(q);
    // let list = await DB.collection(payload.pathList).limit(queryLimit).get();
    const listz = unwrapFirestoreDoc(list);
    commit("setHotList", listz);
    let nbItems = 0;
    if (list.empty) {
      if (payload.loading != null) payload.loading.complete();
    } else {
      nbItems = listz.length;
    }

    if (nbItems < queryLimit) {
      if (payload.loading != null) payload.loading.complete();
    }
  },
};
