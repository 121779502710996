import store from "../store";

const Vote = () =>
  import(/* webpackChunkName: "vote" */ "@/component/vote/voteItem/Vote");

export default [
  {
    path: "/:worldCountry/:worldCountryZone/:worldCountryZoneTheme/:id",
    meta: {
      requiresAuth: false,
    },
    name: "worldCountryZoneThemeVote",
    component: Vote,
    beforeEnter: async (to, from, next) => {
      if (typeof store.state.vote.vote.id === "undefined") {
        let path =
          to.params.worldCountry !== "undefined"
            ? "country/" + to.params.worldCountry
            : "";
        path +=
          to.params.worldCountryZone !== "undefined"
            ? "/zone/" + to.params.worldCountryZone
            : "";
        path +=
          to.params.worldCountryZoneTheme !== "undefined"
            ? "/theme/" + to.params.worldCountryZoneTheme + "/voteList"
            : "";
        await store.dispatch("vote/getVote", {
          path: path,
          id: to.params.id,
        });

        if (store.state.list.worldCountry == "") {
          store.commit("list/setWorldCountry", to.params.worldCountry);
          store.commit("list/setWorldCountryName", to.params.worldCountry);
        }

        if (store.state.list.worldCountryZone == "") {
          store.commit("list/setWorldCountryZone", to.params.worldCountryZone);
          store.commit(
            "list/setWorldCountryZoneName",
            to.params.worldCountryZone
          );
        }

        if (store.state.list.worldCountryZoneTheme == "") {
          store.commit(
            "list/setWorldCountryZoneTheme",
            to.params.worldCountryZoneTheme
          );
          store.commit(
            "list/setWorldCountryZoneThemeName",
            to.params.worldCountryZoneTheme
          );
        }
        store.commit("vote/emptyElectedCalculation");
        next();
      } else {
        next();
      }
    },
  },
  {
    path: "/:worldCountry/:worldCountryTheme/:id",
    meta: {
      requiresAuth: false,
    },
    name: "worldCountryThemeVote",
    component: Vote,
    beforeEnter: async (to, from, next) => {
      // if (typeof store.state.vote.vote.id === "undefined") {
      if (typeof store.state.vote.vote.id === "undefined") {
        let path =
          to.params.worldCountry !== "undefined"
            ? "country/" + to.params.worldCountry
            : "";
        path +=
          to.params.worldCountryTheme !== "undefined"
            ? "/theme/" + to.params.worldCountryTheme + "/voteList"
            : "";
        await store.dispatch("vote/getVote", {
          path: path,
          id: to.params.id,
        });
        if(!store.state.vote.vote) next({ name: 'NotFound' })

        if (store.state.list.worldCountry == "") {
          store.commit("list/setWorldCountry", to.params.worldCountry);
          store.commit("list/setWorldCountryName", to.params.worldCountry);
        }

        if (store.state.list.worldCountryTheme == "") {
          store.commit(
            "list/setWorldCountryTheme",
            to.params.worldCountryTheme
          );
          store.commit(
            "list/setWorldCountryThemeName",
            to.params.worldCountryTheme
          );
        }
        store.commit("vote/emptyElectedCalculation");
        next();
      } else {
        next();
      }
    },
  },
];
