<template>
  <v-dialog
    :value="faq"
    max-width="290"
    max-height="100"
    @click:outside="closeFaq"
  >
    <v-card>
      <v-card-title class="justify-center">Les règles du jeu</v-card-title>
      <v-card-text class="text-center">
        <v-carousel
          v-model="model"
          height="290"
          hide-delimiters
          class="myCarousel"
        >
          <template v-slot:prev="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" x-small>
              <svg-icon type="mdi" :path="arrowLeft" size="12"></svg-icon
            ></v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" x-small id="suivant">
              <svg-icon type="mdi" :path="arrowRight" size="12"></svg-icon
            ></v-btn>
          </template>
          <v-carousel-item key="Bienvenue">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height" align="center" justify="center">
                <div>
                  <h1 class="mb-4">Bienvenue</h1>
                  <p class="mb-3">
                    Demokratio est un jeu sérieux.<br />
                    Le jeu le plus sérieux du monde.
                  </p>
                  <p>
                    Votre avis compte à chaque fois<br />
                    que vous voterez :<br />                  
                    vous changez le monde<br />
                    et le monde le verra bientôt.
                  </p>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item key="PourContre">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height" align="center" justify="center">
                <div>
                  <p class="mb-1 text-caption">
                    Vous avez un avis tranché ?<br />
                    votez "pour" OU "contre" à 100%
                  </p>
                  <v-row>
                    <v-col>
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="2"
                        rounded
                        value="100"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-3"
                        color="red"
                        height="2"
                        rounded
                        value="0"
                      ></v-progress-linear>
                    </v-col>
                    <v-col>
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="2"
                        rounded
                        value="0"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-3"
                        color="red"
                        height="2"
                        rounded
                        value="100"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>

                  <p class="mb-1 text-caption">
                    Vous avez un avis mitigé ?<br />
                    votez "pour" ET "contre"
                  </p>
                  <v-progress-linear
                    class="mb-1"
                    color="blue"
                    height="2"
                    rounded
                    value="80"
                  ></v-progress-linear>
                  <v-progress-linear
                    class="mb-3"
                    color="red"
                    height="2"
                    rounded
                    value="40"
                  ></v-progress-linear>
                  <p class="mb-1 text-caption">
                    Perplexe sur une question de vote ?<br />
                    votez NI "pour" NI "contre"<br />
                    = vote blanc
                  </p>
                  <v-progress-linear
                    class="mb-1"
                    color="blue"
                    height="2"
                    rounded
                    value="0"
                  ></v-progress-linear>
                  <v-progress-linear
                    class="mb-3"
                    color="red"
                    height="2"
                    rounded
                    value="0"
                  ></v-progress-linear>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item key="Nuancer">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height" align="center" justify="center">
                <div>
                  <p class="mb-0 text-caption">
                    A 100% pour une question de vote ?<br />
                    Vous votez 100% "pour"
                  </p>
                  <v-slider
                    color="primary"
                    thumb-label
                    min="0"
                    max="100"
                    value="100"
                    dense
                    class="px-2 sliderFaq"
                  >
                  </v-slider>
                  <p class="mb-0 text-caption">
                    Si vous êtes à moitié "pour" ?<br />
                    Vous votez 50% "pour"
                  </p>
                  <v-slider
                    color="blue"
                    thumb-label
                    value="50"
                    min="0"
                    max="100"
                    dense
                    class="px-2 sliderFaq"
                  >
                  </v-slider>
                  <p class="mb-0 text-caption">
                    Idem avec le "contre"<br />
                    Nuancez votre décision à volonté
                  </p>
                  <v-slider
                    color="blue"
                    thumb-label
                    min="0"
                    max="80"
                    dense
                    class="px-2 sliderFaq2"
                  >
                  </v-slider>
                  <v-slider
                    color="red"
                    thumb-label
                    min="0"
                    max="40"
                    dense
                    class="px-2 sliderFaq2"
                  >
                  </v-slider>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item key="Revote">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height" align="center" justify="center">
                <div>
                  <p class="mb-1">
                    Vous avez changé d'avis ?<br />
                    Tout va bien, vous faites un "revote"
                  </p>
                  <v-btn
                    x-small
                    color="success"
                    depressed
                    fab
                    :class="'mr-2 mb-2 vote-btn ' + disableEvents"
                    large
                    dark
                    :loading="loading"
                    :disabled="loading"
                    @click="revote"
                  >
                    <!-- <v-icon>mdi-vote</v-icon> -->
                    <svg-icon type="mdi" :path="votePath" size="16"></svg-icon>
                    <span class="btn-text">{{ revoteLabel }}</span>
                  </v-btn>
                  <p>
                    Et combien de fois ?<br />
                    Autant de fois<br />
                    que vous changez d'avis
                  </p>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item key="ClassementMajoritaire">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height pt-6" align="start" justify="center">
                <div>
                  <p>
                    Le classement des résultats : <br /><v-btn
                      x-small
                      class="white--text"
                      color="blue"
                      depressed
                      @click="expandMajoritaire = !expandMajoritaire"
                    >
                      Les majoritaires
                      <svg-icon
                        right
                        color="white"
                        type="mdi"
                        :path="medalPath"
                        size="16"
                      ></svg-icon>
                    </v-btn>

                    <br />
                    Calcule tous les votes ayant<br />
                    plus de "pour" que de "contre"
                  </p>
                  <v-expand-transition>
                    <div v-show="expandMajoritaire">
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="5"
                        rounded
                        value="90"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-3"
                        color="red"
                        height="5"
                        rounded
                        value="80"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="5"
                        rounded
                        value="60"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-3"
                        color="red"
                        height="5"
                        rounded
                        value="20"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="5"
                        rounded
                        value="30"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-1"
                        color="red"
                        height="5"
                        rounded
                        value="15"
                      ></v-progress-linear>
                    </div>
                  </v-expand-transition>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item key="ClassementPour">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height pt-6" align="start" justify="center">
                <div>
                  <p>
                    Le classement des résultats : <br /><v-btn
                      x-small
                      class="white--text"
                      color="orange"
                      depressed
                      @click="expandPour = !expandPour"
                    >
                      le top des "pour"
                      <svg-icon
                        right
                        color="white"
                        type="mdi"
                        :path="medalPath"
                        size="16"
                      ></svg-icon>
                    </v-btn>

                    <br />
                    Calcule les meilleurs votes <br />"pour" de la liste<br />
                    <span class="text-caption">
                      ( si un "contre" > "pour" : <br />
                      il peut apparaître ici )
                    </span>
                  </p>

                  <v-expand-transition>
                    <div v-show="expandPour">
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="5"
                        rounded
                        value="95"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-3"
                        color="red"
                        height="5"
                        rounded
                        value="100"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="5"
                        rounded
                        value="90"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-3"
                        color="red"
                        height="5"
                        rounded
                        value="80"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="5"
                        rounded
                        value="70"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-1"
                        color="red"
                        height="5"
                        rounded
                        value="85"
                      ></v-progress-linear>
                    </div>
                  </v-expand-transition>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item key="ClassementFederateur">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height pt-6" align="start" justify="center">
                <div>
                  <p>
                    Le classement des résultats : <br /><v-btn
                      x-small
                      class="white--text"
                      color="purple"
                      depressed
                      @click="expandFederateur = !expandFederateur"
                    >
                      Les + fédérateurs
                      <svg-icon
                        right
                        color="white"
                        type="mdi"
                        :path="medalPath"
                        size="16"
                      ></svg-icon>
                    </v-btn>

                    <br />
                    Calcule les votes<br />
                    qui ont le plus de "pour"<br />
                    et le moins de "contre"<br />
                    en même temps
                  </p>
                  <v-expand-transition>
                    <div v-show="expandFederateur">
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="5"
                        rounded
                        value="55"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-3"
                        color="red"
                        height="5"
                        rounded
                        value="5"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="5"
                        rounded
                        value="60"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-3"
                        color="red"
                        height="5"
                        rounded
                        value="20"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-1"
                        color="blue"
                        height="5"
                        rounded
                        value="30"
                      ></v-progress-linear>
                      <v-progress-linear
                        class="mb-1"
                        color="red"
                        height="5"
                        rounded
                        value="15"
                      ></v-progress-linear>
                    </div>
                  </v-expand-transition>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item key="Medailles">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height" align="center" justify="center">
                <div>
                  <p>
                    La décision finale <br />pourraient se baser <br />sur les
                    votes à 3 médailles<br />
                  </p>

                  <p>
                    <svg-icon
                      right
                      color="blue"
                      type="mdi"
                      :path="medalPath"
                      size="16"
                    ></svg-icon>
                    <svg-icon
                      right
                      color="orange"
                      type="mdi"
                      :path="medalPath"
                      size="16"
                    ></svg-icon>
                    <svg-icon
                      right
                      color="purple"
                      type="mdi"
                      :path="medalPath"
                      size="16"
                    ></svg-icon>
                  </p>

                  <p>
                    ... Et si la tendance persiste<br />
                    dans le temps
                  </p>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item key="Graphique">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height pt-6" align="start" justify="center">
                <div>
                  <p>
                    Voir l'évolution des votes <br />
                    "pour" et "contre" dans le temps ?<br />
                  </p>

                  <p>
                    <v-btn
                      x-small
                      class="white--text"
                      @click="
                        expandSparkline = !expandSparkline;
                        drawSparkline();
                      "
                      ><svg-icon
                        type="mdi"
                        :path="chartMultilinePath"
                        size="16"
                      ></svg-icon
                    ></v-btn>
                  </p>
                  <v-expand-x-transition>
                    <div v-show="expandSparkline">
                      <v-sheet class="stackSheet" :color="color">
                        <v-sparkline
                          :value="sparklineBlueValue"
                          type="trend"
                          color="blue"
                          auto-draw
                          smooth="16"
                          stroke-linecap="round"
                        ></v-sparkline>
                        <v-sparkline
                          class="stackSpark"
                          :value="sparklineRedValue"
                          type="trend"
                          color="red"
                          auto-draw
                          smooth="16"
                          stroke-linecap="round"
                        ></v-sparkline>
                      </v-sheet>
                    </div>
                  </v-expand-x-transition>
                  <p>(à venir)</p>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item key="Rang">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height" align="center" justify="center">
                <div>
                  <p>
                    Faire remonter ou redescendre <br />
                    une question dans la liste ?<br />
                  </p>

                  <p>
                    <v-btn text plain icon small @click="rankUpExample()">
                      <svg-icon
                        small
                        color="yellow"
                        type="mdi"
                        :path="rankUpPath"
                      ></svg-icon> </v-btn
                    ><br />

                    <span>{{ rankExample }}</span
                    ><br />

                    <v-btn text plain icon small @click="rankDownExample()">
                      <svg-icon
                        small
                        color="yellow"
                        type="mdi"
                        :path="rankDownPath"
                      ></svg-icon>
                    </v-btn>
                  </p>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item key="Epingler">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height" align="center" justify="center">
                <div>
                  <p>
                    Epingler une liste ou <br />
                    une question de vote ?
                  </p>

                  <v-btn
                    text
                    plain
                    icon
                    small
                    @click="pinClicked = !pinClicked"
                  >
                    <svg-icon
                      small
                      :color="pinClicked ? 'yellow' : 'white'"
                      type="mdi"
                      :path="pinPath"
                    ></svg-icon>
                  </v-btn>
                  <br />
                  (à venir)
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item key="Masquer">
            <v-sheet :color="color" height="250" tile rounded>
              <v-row class="fill-height" align="center" justify="center">
                <div>
                  <p>
                    Masquer une liste ou <br />
                    une question de vote ?
                  </p>

                  <v-btn
                    text
                    plain
                    icon
                    small
                    @click="hideClicked = !hideClicked"
                  >
                    <svg-icon
                      small
                      :color="hideClicked ? 'yellow' : 'white'"
                      type="mdi"
                      :path="eyeOffPath"
                      size="16"
                    ></svg-icon> </v-btn
                  ><br />
                  (à venir)
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="closeFaq()">
          c'est parti, je décide de vivre
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiMedal,
  mdiChartMultiline,
  mdiArrowRight,
  mdiArrowLeft,
  mdiVote,
  mdiMenuDown,
  mdiMenuUp,
  mdiPin,
  mdiEyeOff,
} from "@mdi/js";

export default {
  name: "faq",
  components: {
    SvgIcon,
  },
  data: function () {
    return {
      rankExample: 777,
      pinClicked: false,
      hideClicked: false,
      revoteLabel: "revote",
      disableEvents: "",
      e1: 1,
      loader: null,
      loading: false,
      medalPath: mdiMedal,
      chartMultilinePath: mdiChartMultiline,
      arrowRight: mdiArrowRight,
      arrowLeft: mdiArrowLeft,
      votePath: mdiVote,
      rankUpPath: mdiMenuUp,
      rankDownPath: mdiMenuDown,
      pinPath: mdiPin,
      eyeOffPath: mdiEyeOff,
      color: "secondary",
      model: 0,
      expandMajoritaire: false,
      expandPour: false,
      expandFederateur: false,
      expandSparkline: false,
      sparklineBlueValue: [],
      sparklineRedValue: [],
    };
  },
  computed: mapState(["faq"]),
  methods: {
    drawSparkline() {
      if (this.expandSparkline) {
        this.sparklineBlueValue = [
          0, 2, 5, 7, 8, 10, 3, 5, 0, 2, 4, 8, 7, 9, 10,
        ];
        this.sparklineRedValue = [0, 2, 1, 9, 6, 1, 3, 7, 10, 5, 1, 5, 3, 4, 2];
      } else {
        this.sparklineBlueValue = [];
        this.sparklineRedValue = [];
      }
    },
    revote: function (event) {
      Promise.resolve()
        .then(() => {
          this.loader = true;
          this.loading = true;
        })
        .then(
          () =>
            new Promise((resolve) => {
              setTimeout(() => resolve(), 500);
            })
        )
        .then(() => {
          this.loader = null;
          this.loading = false;
          this.revoteLabel = "a voté";
        })
        .then(
          () =>
            new Promise((resolve) => {
              setTimeout(() => resolve(), 1000);
            })
        )
        .then(() => {
          this.revoteLabel = "revote";
          document.getElementById("suivant").focus();
        });
    },
    closeFaq() {
      this.$store.commit("openFaq", false);
    },
    rankUpExample() {
      this.rankExample++;
    },
    rankDownExample() {
      this.rankExample--;
    },
    nextQuestion() {},
  },
  // mounted: {
  //   ...mapState(["faq"])
  // }
};
</script>
<style>
.vote-btn .v-btn__content {
  flex-direction: column;
}
.vote-btn span.btn-text {
  font-size: 0.6em;
}
/* .v-window__prev, .v-window__next {
	background: rgba(0, 0, 0, 0.3);
	position: absolute;
	margin: 0 16px;
	top: calc(90% - 20px);
	z-index: 1;
} */
.myCarousel .v-window__prev,
.myCarousel .v-window__next {
  border-radius: 0%;
  top: 250px;
}
.disable-events {
  pointer-events: none;
}
.stackSheet {
  position: relative;
}
.stackSpark {
  position: absolute;
  top: 0;
  left: 0;
}
.sliderFaq {
  height: 28px;
}
.sliderFaq2 {
  height: 20px;
}
</style>
