import store from "../store";

/**
 *
 * world :                        liste des pays du monde                                 /
 * WorldCountryAndZoneAndTheme    liste des zones du monde                                /
 *                                liste des themes du monde                               /
 *
 * worldThemeVoteList             liste des votes d'un theme du monde                     /:worldTheme
 * worldThemeVote                 vote d'un item d'un theme du monde                      /:worldTheme/:id
 *
 * worldZone                      liste des themes d'une zone du monde                    /:worldZone
 * worldZoneThemeList             liste des votes d'un theme d'une zone du monde          /:worldZone/:worldTheme
 * worldZonetThemeVote            vote d'un item d'un theme d'une zone du monde           /:worldZone/:worldTheme/:id
 *
 *
 * MAINTENIR COUNTRY POUR DIFFERENCIER ZONE (GRANDE ZONE, CONTINENT, SOUS-CONTINENT...) ET COUNTRY
 * worldCountry                   liste des zones du pays                                 /:worldCountry
 *                                liste des themes du pays                                /:worldCountry
 *
 * worldCountryThemeVoteList      liste des votes d'un theme du pays                      /:worldCountry/:worldTheme
 * worldCountryThemeVote          vote d'un item d'un theme du pays                       /:worldCountryTheme/:id
 *
 * worldCountryZoneTheme          liste des themes d'une zone d'un pays                   /:worldCountry/:worldCountryZone
 * worldCountry                  liste des sous-zones (récursif)                          /:country
 *
 * worldCountryZoneThemeVoteList  liste des votes d'un theme d'une zone d'un pays         /:worldCountry/:worldCountryZone/:worldCountryZoneTheme
 *
 * worldCountry-zone-theme-vote  vote d'un item d'un theme d'une zone d'un pays          /:country/:zone/:theme/:id
 */

const WorldCountryAndZoneAndTheme = () =>
  import(/* webpackChunkName: "world" */ "@/component/list/world/Home");

// const CountryList = () =>
//   import(/* webpackChunkName: "country" */ "@/component/list/Country");

/**
 * zone components
 */
// / : europe, asie, afrique...
const WorldZoneList = () =>
  // import(/* webpackChunkName: "zone" */ "@/component/list/WorldZone");
  import(/* webpackChunkName: "zone" */ "@/component/list/world/zone/Home");

/**
 * theme components <=> liste des thèmes
 */
// / : war, peace, climat
const WorldThemeHome = () =>
  // import(/* webpackChunkName: "theme" */ "@/component/list/WorldTheme");
  import(/* webpackChunkName: "theme" */ "@/component/list/world/theme/Home");

// /europe/theme/xx
const WorldZoneThemeList = () =>
  // import(/* webpackChunkName: "theme" */ "@/component/list/WorldZoneTheme");
  import(
    /* webpackChunkName: "theme" */ "@/component/list/world/zone/theme/Home"
  );

/**
 * liste des votes
     // WorldTheme : /war, /climat, /peace ...
 // WorldCountryTheme : /fr/presidentielles-2022
 // WorldCountryZoneTheme : /fr/dourdan/municipales-2020
 // WorldZoneTheme : /ue/integration-de-pays
 */
const VoteList = () =>
  import(
    /* webpackChunkName: "voteList" */ "@/component/vote/voteList/VoteList"
  );

const HotList = () =>
import(
/* webpackChunkName: "hotList" */ "@/component/list/HotList"
);


const ComingSoon = () =>
  import(/* webpackChunkName: "comingsoon" */ "@/component/ui/ComingSoon");

function cleanState(state, fields) {
  for (let index = 0; index < fields.length; index++) {
    state[fields[index]] = "";
  }
}

export default [
  /**
   * Liste des tendances : les plus votés depuis les 24 heures ?
   */
  {
    path: "/en-ce-moment",
    name: "hot",
    meta: {
      requiresAuth: false,
    },
    component: HotList,
    // component: ComingSoon,
    beforeEnter: (to, from, next) => {
      store.commit("showBackBtn", false);
      next();
    },
  },

  /**
   * Liste des votes les plus épinglés
   */
  {
    path: "/les-plus-epingles",
    name: "mostPinned",
    meta: {
      requiresAuth: false,
    },
    // component: HotList,
    component: ComingSoon,
    beforeEnter: (to, from, next) => {
      store.commit("showBackBtn", true);
      next();
    },
  },

  /**
   Liste les sous-zones du pays : continent, grande parties, sous-continent...
    */
  {
    path: "/zone/:worldZone",
    name: "worldZone",
    meta: {
      requiresAuth: false,
    },
    component: WorldZoneList,
    beforeEnter: (to, from, next) => {
      // if (from.name != "world") {
        store.commit("vote/resetList");
        store.commit("vote/resetLastListId");
        store.commit("vote/resetLastId");
      // }

      if (store.state.list.worldZone == "") {
        store.commit("list/setWorldZone", to.params.worldZone);
        store.commit("list/setWorldZoneName", to.params.worldZone);
      }
      next();
    },
  },

  /**
   Liste les thèmes d'une zone du pays : continent, grande parties, sous-continent...
    */
  {
    path: "/zone/:worldZone/themes",
    name: "worldZoneTheme",
    meta: {
      requiresAuth: false,
    },
    component: WorldZoneThemeList,
    beforeEnter: (to, from, next) => {
      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");
      store.commit("vote/resetLastId");
      store.commit("list/resetForWorldZoneTheme");
      if (store.state.list.worldZone == "") {
        store.commit("list/setWorldZone", to.params.worldZone);
        store.commit("list/setWorldZoneName", to.params.worldZone);
      }
      next();
    },
  },

  /**
   * Liste des thèmes du monde
   */
  {
    path: "/themes",
    name: "worldTheme",
    meta: {
      requiresAuth: false,
    },
    component: WorldThemeHome,
    beforeEnter: (to, from, next) => {
      store.commit("vote/resetList");
      store.commit("vote/resetLastListId");
      store.commit("vote/resetLastId");

      next();
    },
  },
  /**
   * Liste des votes d'un thème du monde
   */
  {
    path: "/theme/:worldTheme/votes",
    name: "worldThemeVoteList",
    meta: {
      requiresAuth: false,
    },
    component: VoteList,
    beforeEnter: (to, from, next) => {
      store.commit("vote/resetList");
      store.commit("vote/resetLastId");
      store.commit("vote/resetLastListId");
      store.commit("list/resetForWorldTheme");
      if (store.state.list.worldTheme == "") {
        store.commit("list/setWorldTheme", to.params.worldTheme);
        store.commit("list/setWorldThemeName", to.params.worldTheme);
      }
      next();
    },
  },

  /**
   Liste les zones du monde : continent, grande parties, sous-continent...
   23/05/2022 : pas utilisé ?
   */
  // {
  //   path: "/zones",
  //   name: "worldZone",
  //   meta: {
  //     requiresAuth: true,
  //   },
  //   // component: WorldZoneList, TODO à répercuter
  //   component: WorldZoneHome,
  //   beforeEnter: (to, from, next) => {
  //     store.commit("vote/resetList");
  //     store.commit("vote/resetLastListId");
  //     store.commit("vote/resetLastId");

  //     if (store.state.list.worldZone == "") {
  //       store.commit("list/setWorldZone", to.params.worldZone);
  //       store.commit("list/setWorldZoneName", to.params.worldZone);
  //     }
  //     next();
  //   },
  // },
  /**
   * Liste des pays / Liste des zones / Liste des thèmes
   */
  {
    path: "/",
    meta: {
      requiresAuth: false,
    },
    name: "world",
	  redirect: { name: "hot" },
    // redirect: to => {
    //   // the function receives the target route as the argument
    //   // a relative location doesn't start with `/`
    //   // or { path: 'profile'}
    //   return {
    //     name: 'worldCountryTheme',
    //     params: {
    //       worldCountry: 'fr',
    //       worldCountryTheme: 'presidentielles-2022'
    //     }
    //   }
    // },
    component: WorldCountryAndZoneAndTheme,
    beforeEnter: (to, from, next) => {
      if (to.fullPath.includes("admin")) {
        next(false);
      }
      // if(from.name !== null) {
        store.commit("vote/resetList");
        store.commit("vote/resetLastListId");
        store.commit("list/resetAllList");
      // }

      next();
    },
  },
];
