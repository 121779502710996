import Vue from "vue";
import App from "@/component/layout/App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
// import "roboto-fontface/css/roboto/roboto-fontface.css";
// import "@mdi/font/css/materialdesignicons.css";
import "@/assets/main.css";
// import { firestorePlugin } from "vuefire";
import { sync } from "vuex-router-sync";
import VueOnlinePlugin from "vue-navigator-online";
import VueResizeText from "vue-resize-text";
// import { getAuth, onAuthStateChanged } from "firebase/auth";

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})


Vue.use(VueResizeText);
Vue.config.productionTip = false;
Vue.use(VueOnlinePlugin);

sync(store, router);

// const auth = getAuth();
// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     // User is signed in, see docs for a list of available properties
//     // https://firebase.google.com/docs/reference/js/firebase.User
//     const uid = user.uid;
//     console.log("authentifié", user);
//     // ...
//   } else {
//     console.log("authentifié KO");
//     // User is signed out
//     // ...
//   }
// });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
