import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  limit,
  orderBy,
  where,
  startAfter,
} from "firebase/firestore";

// import { DB, FieldValue, Timestamp } from "@/firebase/db";
// import { DB, FieldValue } from `@/firebase/${process.env.VUE_APP_MODE}/db`; //"@/firebase/db"
if (process.env.VUE_APP_MODE == "production") {
  //import { Auth } from '@/firebase/production/auth';
  require("@/firebase/production/db");
} else require("@/firebase/development/db");
let D;
if (process.env.VUE_APP_MODE == "production") {
  //import { Auth } from '@/firebase/production/auth';
  D = require("@/firebase/production/db.js");
} else {
  D = require("@/firebase/development/db.js");
}
const DB = D.DB;
const FieldValue = D.FieldValue;
import { unwrapFirestoreDoc, setToFirestore } from "firewings";
// import { Auth } from "@/firebase/auth";
import aVote from "@/functions/aVote";
import { clearConfigCache } from "prettier";

let queryLimit = 10;

export default {
  updateElectedCriteria: async ({ dispatch, commit, state }, payload) => {
    commit("updateElectedCriteria", payload);
    // let tempArray = [];
    // tempArray = [...state.votes];
    // if (state.electedCriteria.pour) {
    //   console.log("on est ici");
    //   tempArray = tempArray.filter((vote) => {
    //     console.log("on est la", vote.medal.pour);
    //     if (vote.medal.pour) return vote;
    //   });
    //   console.log(tempArray.map((vote) => vote.id));
    //   commit("updateElectedCalculation", {
    //     criteria: "electedPour",
    //     voteIdArray: tempArray.map((vote) => vote.id),
    //   });
    // }

    //   let tempArray = [];
    //   if (state.electedCriteria.pour) {
    //     tempArray = [...state.votes];
    //     tempArray = tempArray.filter((vote) => {
    //       return !(vote.choice.one.score == 0 && vote.choice.two.score >= 0);
    //     });
    //     tempArray.sort(function (a, b) {
    //       // ordre décroissant pour
    //       return b.choice.one.score - a.choice.one.score;
    //     });

    //     if (tempArray.length > Math.round(state.votes.length / 2)) {
    //       commit("updateElectedCalculation", {
    //         criteria: "electedPour",
    //         voteIdArray: tempArray
    //           .map((vote) => vote.id)
    //           .splice(0, Math.round(state.votes.length / 2)),
    //       });
    //     } else {
    //       commit("updateElectedCalculation", {
    //         criteria: "electedPour",
    //         voteIdArray: tempArray.map((vote) => {
    //           return vote.id;
    //         }),
    //       });
    //     }
    //   }

    //   if (state.electedCriteria.ecart) {
    //     if (tempArray.length == 0) {
    //       tempArray = await state.votes.filter((vote, index) => {
    //         return vote.choice.one.score > vote.choice.two.score;
    //       });
    //     }
    //     tempArray.sort(function (a, b) {
    //       return (
    //         b.choice.one.score -
    //         b.choice.two.score -
    //         (a.choice.one.score - a.choice.two.score)
    //       );
    //     });
    //     if (tempArray.length > Math.round(state.votes.length / 2)) {
    //       commit("updateElectedCalculation", {
    //         criteria: "electedEcart",
    //         voteIdArray: tempArray
    //           .map((vote) => vote.id)
    //           .splice(0, Math.round(state.votes.length / 2)),
    //       });
    //     } else {
    //       commit("updateElectedCalculation", {
    //         criteria: "electedEcart",
    //         voteIdArray: tempArray.map((vote) => vote.id),
    //       });
    //     }
    //   }
    //   // if (state.electedCriteria.majorite && state.electedMajorite.length == 0) {
    // if (state.electedCriteria.majorite) {
    //     /**
    //      * OLD
    //      */
    //     // if (tempArray.length == 0) {
    //     //   tempArray = await state.votes.filter((vote, index) => {
    //     //     return vote.choice.one.score > vote.choice.two.score;
    //     //   });
    //     // }
    //     // tempArray.sort(function (a, b) {
    //     //   // ordre décroissant pour
    //     //   return b.choice.one.score - a.choice.one.score;
    //     // });
    //     // commit("updateElectedCalculation", {
    //     //   criteria: "electedMajorite",
    //     //   voteIdArray: tempArray
    //     //     .map((vote) => vote.id)
    //     //     .splice(0, Math.round(tempArray.length / 2)),
    //     // });
    //     tempArray = state.votes.filter((vote, index) => {
    //       return vote.choice.one.score > vote.choice.two.score;
    //     });
    //     tempArray.sort(function (a, b) {
    //       // ordre décroissant pour
    //       return b.choice.one.score - a.choice.one.score;
    //     });

    //     if (tempArray.length > Math.round(state.votes.length / 2)) {
    //       commit("updateElectedCalculation", {
    //         criteria: "electedMajorite",
    //         voteIdArray: tempArray
    //           .map((vote) => vote.id)
    //           .splice(0, Math.round(state.votes.length / 2)),
    //       });
    //     } else {
    //       commit("updateElectedCalculation", {
    //         criteria: "electedMajorite",
    //         voteIdArray: tempArray.map((vote) => vote.id),
    //       });
    //     }
    //   }
    //   // await dispatch("loading", false, {root: true});
    //   // commit("loading", false, {root: true})
  },
  electedCriteriaButtonActivated: async (
    { dispatch, commit, state },
    payload
  ) => {
    // await dispatch("loading", true, {root: true});
    // if(
    // 	state.electedCriteriaButtonActivated.ecart ==
    // 	"white" &&
    //   state.electedCriteriaButtonActivated.majorite ==
    // 	"blue" &&
    //   state.electedCriteriaButtonActivated.pour == "white"
    // ){
    // 	console.log("on va faire qqch", state.electedCriteriaButtonActivated);
    // 	commit("updateElectedCriteria", {
    // 		electedEcart: false,
    // 		electedMajorite: false,
    // 		electedPour: false,
    // 	});
    // }
    // // console.log("on va faire qqchxxxxxxxxxxxx", state.electedCriteriaButtonActivated)
    // else
    // else
    commit("electedCriteriaButtonActivated", payload);
  },
  allVotesLoaded: async ({ commit }, payload) => {
    commit("allVotesLoaded", payload);
  },
  aVote: async ({ commit, dispatch, state, rootState }, payload) => {
    const myVoteData = { ...payload.myVote };
    myVoteData.name = state.vote.name;
    myVoteData.choice = state.vote.choice;
    myVoteData.nbVoters = state.vote.nbVoters;
    // if (!state.countryName)
    //   await dispatch("getCountry", rootState.route.params.country);
    // if (!state.themeName)
    //   await dispatch("getTheme", {
    //     path: "country/" + rootState.route.params.country + "/zone",
    //     id: rootState.route.params.zone,
    //   });
    // if (!state.zoneName)
    //   await dispatch("getZone", {
    //     path:
    //       "country/" +
    //       rootState.route.params.country +
    //       "/zone/" +
    //       rootState.route.params.zone +
    //       "/theme",
    //     id: rootState.route.params.theme,
    //   });
    // console.log("state.vote.", state);
    // myVoteData.countryName = state.countryName;
    // myVoteData.themeName = state.themeName;
    // myVoteData.zoneName = state.zoneName;
    myVoteData.countryName =
      rootState.list.worldCountryName == ""
        ? rootState.list.worldName
        : rootState.list.worldCountryName;
    myVoteData.themeName =
      rootState.list.worldCountryZoneThemeName == ""
        ? rootState.list.worldCountryThemeName == ""
          ? rootState.list.worldZoneThemeName
          : rootState.list.worldCountryThemeName
        : rootState.list.worldCountryZoneThemeName;
    myVoteData.zoneName = state.zoneName;
    rootState.list.worldCountryZoneName == ""
      ? rootState.list.worldZoneName
      : rootState.list.worldCountryZoneName;

    myVoteData.route = {};
    myVoteData.route.name = rootState.route.name; // router.app._route.name;
    myVoteData.route.params = rootState.route.params; // router.app._route.params;

    myVoteData.voteCount = myVoteData.hasOwnProperty("voteCount")
      ? myVoteData.voteCount + 1
      : 1;

    // var aVotez = new aVote(myVoteData, rootState.user.currentUser.uid, state.vote.id, state.vote.path, DB, FieldValue, Timestamp);
    var aVotez = new aVote(
      myVoteData,
      rootState.user.currentUser.uid,
      state.vote.id,
      state.vote.path,
      DB,
      FieldValue
    );
    await aVotez.updateUserVote();
    await aVotez.updateVoteCount();
    await aVotez.voteStatusToPending();
    await aVotez.addVoteToQueue();

    dispatch("getMyVote");
    commit("SHOW_SNACKBAR", { title: "merci" }, { root: true });
    dispatch("user/FETCH_USER_VOTES", rootState.user.currentUser, {
      root: true,
    });
  },
  getMyVote: async ({ commit, state, rootState }, payload) => {
    let userVote = await getDoc(
      doc(
        DB,
        "/users/" + rootState.user.currentUser.uid + "/votes",
        state.vote.id
      )
    );
    // let refUserVote = await DB.collection(
    //   "/users/" + rootState.user.currentUser.uid + "/votes"
    // ).doc(state.vote.id);
    // let userVote = await refUserVote.get();
    const myVote = unwrapFirestoreDoc(userVote);
    if (!myVote) {
      commit("myVote", { one: 0, two: 0, voteCount: 0 });
    } else {
      commit("myVote", myVote);
    }
  },
  getVote: async ({ commit }, payload) => {
    const voteSnapshot = await getDoc(doc(DB, payload.path, payload.id));
    // let voteSnapshot = await DB.collection(payload.path).doc(payload.id).get();
    const vote = unwrapFirestoreDoc(voteSnapshot);
    commit("activeVote", vote);
  },
  getCountry: async ({ commit }, payload) => {
    const country = await getDoc(doc(DB, "country", payload));
    const countryz = unwrapFirestoreDoc(country);
    commit("setCountryName", countryz.name);
    commit("setWorldCountryZoneType", countryz.zoneType);
  },
  getZone: async ({ commit }, payload) => {
    // let zone = await DB.collection(payload.path).doc(payload.id).get();
    let zone = await getDoc(doc(DB, payload.path, payload.id));
    const zonez = unwrapFirestoreDoc(zone);
    commit("setZoneName", zonez.name);
  },
  getTheme: async ({ commit }, payload) => {
    // let theme = await DB.collection(payload.path).doc(payload.id).get();
    let theme = await getDoc(doc(DB, payload.path, payload.id));
    const themez = unwrapFirestoreDoc(theme);
    commit("setThemeName", themez.name);
  },
  updateListParam: async ({ commit, dispatch, state, rootState }, payload) => {
    commit("setListParam", payload);
  },
  getList: async ({ commit, dispatch, state, rootState }, payload) => {
    let q;
    /*
    Pour ne pas avoir à recalculer la liste
     */
    // TODO : ko réparer le chargement de liste
    // if (
    //   // rootState.list.worldChoice == "zone" &&
    //   (payload.typeList == "zone" &&
    //   rootState.route.path == rootState.route.from.path &&
    //     // rootState.list.worldZone == rootState.list.prevWorldZone &&
    //     state.prevZonePathList == payload.pathList &&
    //     state.listZone.length > 0) ||
    //   // rootState.list.worldChoice == "theme" &&
    //   (payload.typeList == "theme" &&
    //     state.prevThemePathList == payload.pathList &&
    //     // rootState.list.worldTheme == rootState.list.prevWorldTheme &&
    //     state.listTheme.length > 0) ||
    //   // rootState.list.worldChoice == "country" &&
    //   (payload.typeList == "country" &&
    //     // state.prevCountryPathList == payload.pathList &&
    //     //rootState.list.worldCountry == rootState.list.prevWorldCountry &&
    //     state.listCountry.length > 0)
    // ) {
    //   console.log(
    //     "la liste est la meme -----------------------------------------------------------------",
    //     "donc ne pas recalculer"
    //   );
    //   console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", "state.prevZonePathList == payload.pathList", state.prevZonePathList, payload.pathList, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");

    //   if (payload.loading != null) payload.loading.complete();
    // } else {
    /*
      Creation de listes
      */
    if (
      (state.lastListId == "" && payload.typeList == "vote") ||
      (state.lastListCountryId == "" && payload.typeList == "country") ||
      (state.lastListThemeId == "" && payload.typeList == "theme") ||
      (state.lastListZoneId == "" && payload.typeList == "zone")
    ) {
      // commit("resetList");

      /**
       * TODO il faut absolumment verifier la liste précédente :
       * si elle est identique à la prochaine, ne pas recalculer.
       * if prevPathListTheme == pathList => ne rien faire sinon reset
       * if prevPathListZone == pathList => ne rien faire sinon reset
       * if prevPathListCountry == pathList => ne rien faire sinon reset
       *
       * TODO 24/05/2022 : identifier la liste de la zone : world, country
       * on en a besoin pour la requete dans parentZone
       * idem pour le theme et parentTheme
       *
       *
       */
      /*
          WORLD
          */
      if (
        rootState.route.name == "worldTheme" ||
        rootState.route.name == "worldZone" ||
        rootState.route.name == "world"
      ) {
        /**
              Gestion des zones
              */
        if (
          rootState.list.worldChoice == "zone" ||
          rootState.route.name == "worldZone"
        ) {
          if (payload.typeList == "zone") {
            commit("resetList", "listZone");

            // il s'agit d'une zone ou d'une sous-zone car on vient directement par cette url
            if (rootState.route.from.name === null) {
              console.log(
                "il s'agit d'une zone ou d'une sous-zone car on vient directement par cette url",
                rootState.route.params.worldZone
              );
              q = query(
                collection(DB, payload.pathList),
                where(
                  "parentZone",
                  "array-contains",
                  rootState.route.params.worldZone
                ),
                where("visible", "==", true),
                orderBy("rank", "asc"),
                limit(queryLimit)
              );
              // il s'agit d'une sous-zone
              let list = await getDocs(q);
              const listz = unwrapFirestoreDoc(list);
              // if (listz.length > 0) {
              //   commit("setPrevPathList", {
              //     prevPathList: "",
              //     typeList: "zone",
              //   });
              // }
              // // il s'agit d'une zone
              // else {
              //   console.log("il s'agit d'une zone");
              //   q = query(
              //     collection(DB, payload.pathList),
              //     where("parentZone", "==", []),
              //     orderBy("rank", "desc"),
              //     limit(queryLimit)
              //   );
              //   commit("setPrevPathList", {
              //     prevPathList: payload.pathList,
              //     typeList: payload.typeList,
              //   });
              // }
            } else if (
              // il s'agit d'une zone et on a navigué avant sur l'application
              rootState.list.worldZone == "" &&
              rootState.route.from.name !== null
            ) {
              q = query(
                collection(DB, payload.pathList),
                where("parentZone", "==", []),
                where("visible", "==", true),
                orderBy("rank", "asc"),
                limit(queryLimit)
              );
              commit("setPrevPathList", {
                prevPathList: payload.pathList,
                typeList: payload.typeList,
              });
            }
            // cas d'une sous-zone
            else {
              q = query(
                collection(DB, payload.pathList),
                where("parentZone", "array-contains", rootState.list.worldZone),
                where("visible", "==", true),
                orderBy("rank", "asc"),
                limit(queryLimit)
              );

              // pour vider la liste de l'autre type => 15/06/2022 inutile ?? => remplacé par ; puis traité dans le mutations feedlist
              commit("setPrevPathList", {
                prevPathList: "",
                typeList: "zone",
              });
              // commit("setSubZoneFlag", true)
              // commit("setSubThemeFlag", true)
              commit("resetList", "listZone");
              commit("resetList", "listTheme");
            }
            //     commit("list/setPrevWorldZone", rootState.list.worldZone, {
            //       root: true,
            // });
          }
          if (payload.typeList == "theme") {
            commit("resetList", "listTheme");
            // il s'agit d'un theme
            if (rootState.list.worldTheme == "") {
              q = query(
                collection(DB, payload.pathList),
                where("parentTheme", "==", []),
                where("visible", "==", true),
                orderBy("rank", "asc"),
                limit(queryLimit)
              );
            }
            // cas d'un sous-theme
            else {
              q = query(
                collection(DB, payload.pathList),
                where(
                  "parentTheme",
                  "array-contains",
                  rootState.list.worldTheme
                ),
                where("visible", "==", true),
                orderBy("rank", "asc"),                
                limit(queryLimit)
              );
            }
            // commit("list/setPrevWorldTheme", rootState.list.worldTheme, {
            //   root: true,
            // });
            commit("setPrevPathList", {
              prevPathList: payload.pathList,
              typeList: payload.typeList,
            });
          }
        } else if (rootState.list.worldChoice == "theme") {
          /**
           Gestion des themes
           */
          if (payload.typeList == "theme") {
            commit("resetList", "listTheme");
            // il s'agit d'un theme
            if (rootState.list.worldTheme == "") {
              q = query(
                collection(DB, payload.pathList),
                where("parentTheme", "==", []),
                where("visible", "==", true),
                orderBy("rank", "asc"),                
                limit(queryLimit)
              );
            }
            // cas d'un sous-theme
            else {
              q = query(
                collection(DB, payload.pathList),
                where(
                  "parentTheme",
                  "array-contains",
                  rootState.list.worldTheme
                ),
                where("visible", "==", true),
                orderBy("rank", "asc"),                
                limit(queryLimit)
              );
            }
            // commit("list/setPrevWorldTheme", rootState.list.worldTheme, {
            //   root: true,
            // });
            commit("setPrevPathList", {
              prevPathList: payload.pathList,
              typeList: payload.typeList,
            });
          }
        } else if (
          /**
           Gestion des pays
           */
          rootState.list.worldChoice == "country" &&
          (rootState.route.name !== "worldZone" ||
            rootState.route.name !== "worldTheme")
        ) {
          commit("resetList", "listCountry");
          q = query(
            collection(DB, payload.pathList),
            where("visible", "==", true),                        
            orderBy("rank", "asc"),
            limit(queryLimit)
          );
        }
      } else if (
        /*
          WORLDCOUNTRY
          */
        rootState.route.name == "worldCountryTheme" ||
        rootState.route.name == "worldCountryZone" ||
        rootState.route.name == "worldCountry"
      ) {
        /**
               Gestion des zones
               */
        if (rootState.list.worldCountryChoice == "zone") {
          if (payload.typeList == "zone") {
            commit("resetList", "listZone");
            // il s'agit d'une zone
            if (rootState.list.worldCountryZone == "") {
              q = query(
                collection(DB, payload.pathList),
                where("parentZone", "==", []),
                where("visible", "==", true),               
                orderBy("rank", "asc"),
                limit(queryLimit)
              );
            }
            // cas d'une sous-zone
            else {
              q = query(
                collection(DB, payload.pathList),
                where(
                  "parentZone",
                  "array-contains",
                  rootState.list.worldCountryZone
                ),
                where("visible", "==", true),                
                orderBy("rank", "asc"),
                limit(queryLimit)
              );
            }
            // commit(
            //   "list/setPrevWorldZone",
            //   rootState.list.worldCountryZone,
            //   {
            //     root: true,
            //   }
            // );
            commit("setPrevPathList", {
              prevPathList: payload.pathList,
              typeList: payload.typeList,
            });
          }
        }
        /**
             Gestion des themes
             */
        if (rootState.list.worldCountryChoice == "theme") {
          if (payload.typeList == "theme") {
            commit("resetList", "listTheme");
            // il s'agit d'un theme
            if (rootState.list.worldCountryTheme == "") {
              q = query(
                collection(DB, payload.pathList),
                where("parentTheme", "==", []),
                where("visible", "==", true),
                orderBy("rank", "asc"),
                limit(queryLimit)
              );
            }
            // cas d'un sous-theme
            else {
              q = query(
                collection(DB, payload.pathList),
                where(
                  "parentTheme",
                  "array-contains",
                  rootState.list.worldCountryTheme
                ),
                where("visible", "==", true),
                orderBy("rank", "asc"),
                limit(queryLimit)
              );
            }
            // commit(
            //   "list/setPrevWorldTheme",
            //   rootState.list.worldCountryTheme,
            //   {
            //     root: true,
            //   }
            // );
            commit("setPrevPathList", {
              prevPathList: payload.pathList,
              typeList: payload.typeList,
            });
          }
        }
      } else if (rootState.route.name == "worldCountryZoneTheme") {
        /*
          WORLDCOUNTRYZONE : il peut y avoir des sous-theme uniquement TODO
          console.log("est ce quon est là ?");
          */
        if (payload.typeList == "theme") {
          commit("resetList", "listTheme");
          // il s'agit d'un theme
          if (rootState.list.worldCountryZoneTheme == "") {
            q = query(
              collection(DB, payload.pathList),
              where("parentTheme", "==", []),
              where("visible", "==", true),
              orderBy("rank", "asc"),
              limit(queryLimit)
            );
          }
          // cas d'un sous-theme
          else {
            q = query(
              collection(DB, payload.pathList),
              where(
                "parentTheme",
                "array-contains",
                rootState.list.worldCountryZoneTheme
              ),
              where("visible", "==", true),
              orderBy("rank", "asc"),
              limit(queryLimit)
            );
          }
          // commit(
          //   "list/setPrevWorldTheme",
          //   rootState.list.worldCountryZoneTheme,
          //   {
          //     root: true,
          //   }
          // );
          commit("setPrevPathList", {
            prevPathList: payload.pathList,
            typeList: payload.typeList,
          });
        }
      } else if (rootState.route.name == "worldCountryTheme") {
        /*
          WORLDCOUNTRYTHEME : il peut y avoir des sous-theme uniquement TODO
          console.log("est ce quon est là ?");
          */
        if (payload.typeList == "theme") {
          commit("resetList", "listTheme");
          // il s'agit d'un theme
          if (rootState.list.worldCountryTheme == "") {
            q = query(
              collection(DB, payload.pathList),
              where("parentTheme", "==", []),
              where("visible", "==", true),
              orderBy("rank", "asc"),
              limit(queryLimit)
            );
          }
          // cas d'un sous-theme
          else {
            q = query(
              collection(DB, payload.pathList),
              where(
                "parentTheme",
                "array-contains",
                rootState.list.worldCountryTheme
              ),
              where("visible", "==", true),
              orderBy("rank", "asc"),
              limit(queryLimit)
            );
          }
          // commit(
          //   "list/setPrevWorldTheme",
          //   rootState.list.worldCountryZoneTheme,
          //   {
          //     root: true,
          //   }
          // );
          commit("setPrevPathList", {
            prevPathList: payload.pathList,
            typeList: payload.typeList,
          });
        }
      }

      /**TEST */
      let list = await getDocs(q);
      // let list = await DB.collection(payload.pathList).limit(queryLimit).get();
      const listz = unwrapFirestoreDoc(list);
      commit("feedList", {
        typeList: payload.typeList,
        list: listz,
      });
      let nbItems = 0;
      if (list.empty) {
        if (payload.loading != null) payload.loading.complete();
      } else {
        nbItems = listz.length;
        commit("setLastListId", {
          lastListId: list.docs[list.docs.length - 1].id,
          typeList: payload.typeList,
        });
      }

      if (nbItems < queryLimit) {
        if (payload.loading != null) payload.loading.complete();
      } else {
        dispatch("getListMore", payload);
      }
      /**FIN TEST */
    }
    //} // TODO : ko réparer le chargement de liste
    else {
      dispatch("getListMore", payload);
    }

    // dispatch("getListMore", payload);
  },
  getListMore: async ({ commit, state }, payload) => {
    let q;
    let payloadz = payload;
    if (
      (state.lastListId != "" && payloadz.typeList == "vote") ||
      (state.lastListCountryId != "" && payloadz.typeList == "country") ||
      (state.lastListThemeId != "" && payloadz.typeList == "theme") ||
      (state.lastListZoneId != "" && payloadz.typeList == "zone")
    ) {
      let lastListId =
        state.lastListId != "" && payloadz.typeList == "vote"
          ? state.lastListId
          : state.lastListCountryId != "" && payloadz.typeList == "country"
          ? state.lastListCountryId
          : state.lastListThemeId != "" && payloadz.typeList == "theme"
          ? state.lastListThemeId
          : state.lastListZoneId != "" && payloadz.typeList == "zone"
          ? state.lastListZoneId
          : "";
      let lastList = await getDoc(doc(DB, payload.pathList, lastListId));
      // let lastList = await DB.collection(payload.pathList)
      //   .doc(state.lastListId)
      //   .get();
      if (lastList.exists) {
        let q = query(
          collection(DB, payload.pathList),
          startAfter(lastList),
          where("visible", "==", true),
          orderBy("rank", "asc"),          
          limit(queryLimit)
        );
        let list = await getDocs(q);
        // let list = await DB.collection(payload.pathList)
        //   .startAfter(lastList)
        //   .limit(queryLimit)
        //   .get();

        const listz = unwrapFirestoreDoc(list);

        if (list.docs.length > 0) {
          commit("feedList", {
            typeList: payload.typeList,
            list: listz,
          });
          if (payloadz.loading != null) payloadz.loading.loaded();
          commit("setLastListId", {
            lastListId: list.docs[list.docs.length - 1].id,
            typeList: payload.typeList,
          });
        } else {
          if (payloadz.loading != null) payloadz.loading.complete();
        }
      }
    } else {
      if (payloadz.loading != null) payloadz.loading.complete();
    }
  },
  selectVote: ({ commit }, vote) => {
    commit("activeVote", vote);
  },
  bind: async ({ commit, dispatch, state, rootState }, payload) => {
    // evite de recharger les données si on vient d'un vote => ko avec infinite
    // if (
    //   rootState.route.from.name !== null &&
    //   rootState.route.from.name.endsWith("Vote") &&
    //   state.votes.length != rootState.list.worldCountryThemeVoteListLength
    // ) {
    //   console.log("roooot", "on ne charge pas les votes");
    //   payload.loading.reset()
    // } 
    // else {
      queryLimit = payload.queryLimit ? payload.queryLimit : queryLimit;
      // commit("emptyElectedCalculation");
      // commit("feedVotes", [])
      if (state.lastId == "") {
        // forcer la liste vide
        // commit("emptyElectedCalculation");
        // TODO remplacer lastId par lastVote qui grâce au unwrapfirestore, possède un path
        // et  list[list.length-1].path "country/fr/zone/dourdan/theme/municipales-2020"
        let votes;
        // 30/03/2022 : ce n'est pas rootState.vote.list ??!!!!!!
        // il vient quand ici ? 27/05/2022
        if (rootState.list.length > 0) {
          // let q = query(
          //   collection(DB, rootState.list[rootState.list.length - 1].path + "/voteList"),
          //   orderBy("rank", "desc"),
          //   limit(queryLimit)
          // );
          votes = await getDocs(
            collection(
              DB,
              rootState.list[rootState.list.length - 1].path + "/voteList"
            )
          );
          // var votes = await DB.collection(
          //   rootState.list[rootState.list.length - 1].path + "/voteList"
          // ).get();
        } else {
          // var votes = await DB.collection("country")
          //   .doc(rootState.route.params.worldCountry)
          //   .collection("zone")
          //   .doc(rootState.route.params.worldCountryZone)
          //   .collection("theme")
          //   .doc(rootState.route.params.worldCountryZoneTheme)
          //   .collection("voteList")
          //   .orderBy("rank", "desc")
          //   .limit(queryLimit)
          //   .get();
          let path;
          if (payload.theme.name == "worldThemeVoteList")
            path = "/theme/" + payload.theme.params.worldTheme + "/voteList";
          if (payload.theme.name == "worldZoneThemeVoteList")
            path =
              "zone/" +
              payload.theme.params.worldZone +
              "/theme/" +
              payload.theme.params.worldZoneTheme +
              "/voteList";
          if (payload.theme.name == "worldCountryThemeVoteList")
            path =
              "country/" +
              payload.theme.params.worldCountry +
              "/theme/" +
              payload.theme.params.worldCountryTheme +
              "/voteList";
          if (payload.theme.name == "worldCountryZoneThemeVoteList")
            path =
              "country/" +
              payload.theme.params.worldCountry +
              "/zone/" +
              payload.theme.params.worldCountryZone +
              "/theme/" +
              payload.theme.params.worldCountryZoneTheme +
              "/voteList";

          let q = query(
            collection(DB, path),
            orderBy("rank", "desc"),
            limit(queryLimit)
          );          
          votes = await getDocs(q);
          // var votes = await DB.collection(path)
          //   .orderBy("rank", "desc")
          //   .limit(queryLimit)
          //   .get();
        }
        const votez = unwrapFirestoreDoc(votes);        
        commit("feedVotes", votez);
        let nbItems;        
        if (votez.length == 0) {
          if (payload.loading != null) payload.loading.complete();
          nbItems = 0;
        } else {
          nbItems = votez.length;
          commit("setLastId", votez[votez.length - 1].id);
        }
        if (nbItems < queryLimit) {
          if (payload.loading != null) payload.loading.complete();
        } else {
          dispatch("bindMore", payload);
        }
      } 
      else {
        dispatch("bindMore", payload);        
      }
    //}
  },
  bindMore: async ({ commit, dispatch, state, rootState }, payload) => {
    queryLimit = payload.queryLimit ? payload.queryLimit : queryLimit;
    let payloadz = payload;
    let path;
    if (payloadz.theme.name == "worldThemeVoteList")
      path = "/theme/" + payload.theme.params.worldTheme + "/voteList";
    if (payloadz.theme.name == "worldZoneThemeVoteList")
      path =
        "zone/" +
        payload.theme.params.worldZone +
        "/theme/" +
        payload.theme.params.worldZoneTheme +
        "/voteList";
    if (payloadz.theme.name == "worldCountryThemeVoteList")
      path =
        "country/" +
        payload.theme.params.worldCountry +
        "/theme/" +
        payload.theme.params.worldCountryTheme +
        "/voteList";
    if (payloadz.theme.name == "worldCountryZoneThemeVoteList")
      path =
        "country/" +
        payload.theme.params.worldCountry +
        "/zone/" +
        payload.theme.params.worldCountryZone +
        "/theme/" +
        payload.theme.params.worldCountryZoneTheme +
        "/voteList";
   
    if (state.lastId != "") {
      // var lastVote = await DB.collection("country")
      //   .doc(rootState.route.params.worldCountry)
      //   .collection("zone")
      //   .doc(rootState.route.params.worldCountryZone)
      //   .collection("theme")
      //   .doc(rootState.route.params.worldCountryZoneTheme)
      //   .collection("voteList")
      //   .doc(state.lastId)
      //   .get();
      // var lastVote = await DB.collection(path).doc(state.lastId).get();
      var lastVote = await getDoc(doc(DB, path, state.lastId));

      if (lastVote.exists) {
        // var votes = await DB.collection("country")
        //   .doc(rootState.route.params.worldCountry)
        //   .collection("zone")
        //   .doc(rootState.route.params.worldCountryZone)
        //   .collection("theme")
        //   .doc(rootState.route.params.worldCountryZoneTheme)
        //   .collection("voteList")
        let q = query(
          collection(DB, path),
          orderBy("rank", "desc"),
          startAfter(lastVote),
          limit(queryLimit)
        );
        let votes = await getDocs(q);
        // var votes = await DB.collection(path)
        //   .orderBy("rank", "desc")
        //   .startAfter(lastVote)
        //   .limit(queryLimit)
        //   .get();
        const votez = unwrapFirestoreDoc(votes);
        if (votez && votez.length > 0) {
          commit("feedVotes", votez);
          if (payloadz.loading != null) payloadz.loading.loaded();
          commit("setLastId", votez[votez.length - 1].id);
          // dans le cas où tous les votes sont chargés
          // console.log(
          //   "est ce que les votes sont tous charges ???",
          //   state.votes.length,
          //   rootState.list.worldCountryThemeVoteListLength
          // );
          // if (
          //   state.votes.length == rootState.list.worldCountryThemeVoteListLength
          // ) {
          //   // commit("loading", false, { root: true });
          //   await dispatch("updateElectedCriteria");
          // }
          // if (rootState.loading == true) {
          //   // commit("loading", false, { root: true }); // pour le classement, a optimiser
          // }
        } else {
          if (payloadz.loading != null) payloadz.loading.complete();
        }
      } else {
        if (payloadz.loading != null) payloadz.loading.complete();
      }
    }
  },
  bindAll: async ({ commit, dispatch, state, rootState }, payload) => {
    queryLimit = payload.queryLimit ? payload.queryLimit : queryLimit;
    let payloadz = payload;
    let path;
    if (payloadz.theme.name == "worldThemeVoteList")
      path = "/theme/" + payload.theme.params.worldTheme + "/voteList";
    if (payloadz.theme.name == "worldZoneThemeVoteList")
      path =
        "zone/" +
        payload.theme.params.worldZone +
        "/theme/" +
        payload.theme.params.worldZoneTheme +
        "/voteList";
    if (payloadz.theme.name == "worldCountryThemeVoteList" || payloadz.theme.name == "worldCountryThemeVoteListResult")
      path =
        "country/" +
        payload.theme.params.worldCountry +
        "/theme/" +
        payload.theme.params.worldCountryTheme +
        "/voteList";
    if (payloadz.theme.name == "worldCountryZoneThemeVoteList")
      path =
        "country/" +
        payload.theme.params.worldCountry +
        "/zone/" +
        payload.theme.params.worldCountryZone +
        "/theme/" +
        payload.theme.params.worldCountryZoneTheme +
        "/voteList";
    if (state.lastId != "") {
    }
    // var lastVote = await getDoc(doc(DB, path, state.lastId));

    // if (lastVote.exists) {
    let q = query(
      collection(DB, path),
      orderBy("rank", "desc"),
      // startAfter(lastVote),
      limit(queryLimit)
    );
    let votes = await getDocs(q);
    const votez = unwrapFirestoreDoc(votes);
    if (votez && votez.length > 0) {
      commit("feedVotesAllReload", votez);
      commit("setLastId", votez[votez.length - 1].id);
      // dans le cas où tous les votes sont chargés
      // console.log(
      //   "est ce que les votes sont tous charges ??? BINDALL",
      //   state.votes.length,
      //   rootState.list.worldCountryThemeVoteListLength
      // );
      // if (
      //   state.votes.length == rootState.list.worldCountryThemeVoteListLength
      // ) {
      //   //test
      //   // commit("updateElectedCriteria", {
      //   //   electedEcart:
      //   //     state.electedCriteriaButtonActivated.ecart == "purple"
      //   //       ? true
      //   //       : false,
      //   //   electedMajorite:
      //   //     state.electedCriteriaButtonActivated.majorite == "blue"
      //   //       ? true
      //   //       : false,
      //   //   electedPour:
      //   //     state.electedCriteriaButtonActivated.pour == "orange"
      //   //       ? true
      //   //       : false,
      //   // });
      //   // commit("loading", false, { root: true });
      //   await dispatch("updateElectedCriteria");
      // }
      if (rootState.loading == true) {
        // commit("loading", false, { root: true }); // pour le classement, a optimiser
      }
    }
    // }
  },
};
