export default {
  list: (state) => state.list,
  electedCriteria: (state) => state.electedCriteria,
  electedEcart: (state) => state.electedEcart,
  electedPour: (state) => state.electedPour,
  electedMajorite: (state) => state.electedMajorite,
  electedButtonEcart: (state) => state.electedCriteriaButtonActivated.ecart,
  electedButtonPour: (state) => state.electedCriteriaButtonActivated.pour,
  electedButtonMajorite: (state) =>
    state.electedCriteriaButtonActivated.majorite,
  votesMajorite: (state) => {
    if (state.electedCriteria.majorite && state.electedMajorite.length == 0) {
      // if (state.electedCriteria.majorite) {
      let tempArray = [];
      tempArray = state.votes.filter((vote) =>
        state.electedMajorite.includes(vote.id)
      );
      // tempArray = state.votes.filter((vote, index) => {
      //   return vote.choice.one.score > vote.choice.two.score;
      // });
      tempArray.sort(function (a, b) {
        // ordre décroissant pour
        return b.choice.one.score - a.choice.one.score;
      });
      return tempArray;
    } else return state.electedMajorite;
  },
  votes: (state) => {
    // on est forcément dans la page des résultats
    if (
      state.electedCriteria.pour ||
      state.electedCriteria.ecart ||
      state.electedCriteria.majorite
    ) {
      let tempArray = [];
      // TODO ICI APPELER UNE VOTE LISTE DU STATE QUI CORRESPOND À TOUS LES VOTES
      tempArray = [...state.votes];
      tempArray = tempArray.filter((vote) => {
        if (
          (vote.medal.pour && state.electedCriteria.pour) ||
          (vote.medal.federateur && state.electedCriteria.ecart) ||
          (vote.medal.majoritaire && state.electedCriteria.majorite)
        ) {
          return vote;
        }
      });

      if (state.electedCriteria.pour || state.electedCriteria.majorite) {
        tempArray.sort(function (a, b) {
          // ordre décroissant pour
          return b.choice.one.score - a.choice.one.score;
        });
      } else {
        tempArray.sort(function (a, b) {
          return (
            b.choice.one.score -
            b.choice.two.score -
            (a.choice.one.score - a.choice.two.score)
          );
        });
      }

      return tempArray;
    } 
    // sinon c'est la page de la liste triée par rang par défaut
    else {
      // todo refilter selon le rank
      if(state.allVotesLoaded && !state.displayAllVotes) {
        return state.votes.slice(0, 20);
      }
      else if(state.displayAllVotes) {
        return state.votes;
      }
      else {
        return state.votes;
      }
    }

    // if (state.electedCriteria.pour) {
    // 	let tempArray = [];
    // 	tempArray = [...state.votes]
    // 	tempArray = tempArray.filter((vote) => {
    // 		if (vote.medal.pour) return vote;
    // 	});

    // 	return tempArray;
    // }
    // if (state.electedCriteria.ecart) {
    // 	let tempArray = [];
    // 	tempArray = state.votes.filter((vote) =>
    // 		state.electedEcart.includes(vote.id)
    // 	);

    // 	// if (tempArray.length == 0) {
    // 	//   tempArray = Array.from(state.votes);
    // 	// }
    // 	tempArray.sort(function (a, b) {
    // 		return (
    // 			b.choice.one.score -
    // 			b.choice.two.score -
    // 			(a.choice.one.score - a.choice.two.score)
    // 		);
    // 	});
    // 	return tempArray;
    // }
    // if (state.electedCriteria.majorite) {

    // 	console.log("ESTCEQUELONESTLA,,,");
    // 	let tempArray = [];
    // 	tempArray = state.votes.filter((vote) =>
    // 		state.electedMajorite.includes(vote.id)
    // 	);
    // 	// tempArray = state.votes.filter((vote, index) => {
    // 	//   return vote.choice.one.score > vote.choice.two.score;
    // 	// });
    // 	tempArray.sort(function (a, b) {
    // 		// ordre décroissant pour
    // 		return b.choice.one.score - a.choice.one.score;
    // 	});
    // 	return tempArray;
    // } else {
    // 	console.log("ONESTLA");
    // 	if (state.electedCriteriaReset) return state.votes.slice(0, 20);
    // 	else return state.votes;
    // 	// return state.votes.slice(0, 20);
    // }

    /**
	 * OLD 
	 *     if (
		!state.electedCriteria.pour &&
		  !state.electedCriteria.majorite &&
		  !state.electedCriteria.ecart
		) {
		  state.votes.sort(function (a, b) {
			// ordre décroissant pour
			return b.rank - a.rank;
		});
		return state.votes;
	}
	let tempArray = [];
	if (state.electedCriteria.pour) {
		tempArray = state.votes.filter((vote) =>
			state.electedPour.includes(vote.id)
		  );
		  tempArray.sort(function (a, b) {
			  // ordre décroissant pour
			return b.choice.one.score - a.choice.one.score;
		  });
		}
		if (state.electedCriteria.ecart) {
			if (tempArray.length == 0) {
				tempArray = Array.from(state.votes);
		  }
		  tempArray.sort(function (a, b) {
			return (
			  b.choice.one.score -
			  b.choice.two.score -
			  (a.choice.one.score - a.choice.two.score)
			  );
			});
		}
		if (state.electedCriteria.majorite) {
		  if (tempArray.length == 0) {
			  tempArray = Array.from(state.votes);
			}
		  tempArray.sort(function (a, b) {
			  // ordre décroissant pour
			  return b.choice.one.score - a.choice.one.score;
			});
		}
		return tempArray;
		*/
  },
  vote: (state) => state.vote,
  myVote: (state) => state.myVote,
  countryName: (state) => state.countryName,
  zoneName: (state) => state.zoneName,
  themeName: (state) => state.themeName,
};
