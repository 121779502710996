
export default {
  setWorld(state, val) {
    state.world = val;
  },

  setWorldName(state, val) {
    state.worldName = val;
  },
  
  setWorldCountry(state, val) {
    state.worldCountry = val;
  },
  setWorldCountryName(state, val) {
    state.worldCountryName = val;
  },
  
  setWorldCountryZone(state, val) {
    if(state.worldCountryZone != val) {
      state.worldCountryZoneTheme = "";
      state.worldCountryZoneThemeName = "";
      state.worldCountryZoneThemeVoteName = ""
    }
    state.worldCountryZone = val;
  },
  
  setWorldCountryZoneName(state, val) {
    state.worldCountryZoneName = val;
  },
  
  setWorldCountryZoneTheme(state, val) {
    state.worldCountryZoneTheme = val;
  },
  setWorldCountryZoneThemeName(state, val) {
    state.worldCountryZoneThemeName = val;
  },
  setWorldCountryZoneThemeVoteName(state, val) {
    state.worldCountryZoneThemeVoteName = val;
  },
  
  setWorldCountryThemeVoteListLength(state, val) {
    state.worldCountryThemeVoteListLength = val;
  },
  setWorldCountryTheme(state, val) {
    state.worldCountryTheme = val;
  },
  setWorldCountryThemeName(state, val) {
    state.worldCountryThemeName = val;
  },
  setWorldCountryThemeVoteName(state, val) {
    state.worldCountryThemeVoteName = val;
  },

  setWorldZone(state, val) {
    state.worldZone = val;
  },
  setWorldZoneName(state, val) {
    state.worldZoneName = val;
  },

  setWorldZoneTheme(state, val) {
    state.worldZoneTheme = val;
  },
  setWorldZoneThemeName(state, val) {
    state.worldZoneThemeName = val;
  },

  setWorldCountryZoneThemeVoteName(state, val) {
    state.worldCountryZoneThemeVoteName = val;
  },


  setWorldTheme(state, val) {
    state.worldTheme = val;
  },
  setWorldThemeName(state, val) {
    state.worldThemeName = val;
  },
  setWorldThemeVoteName(state, val) {
    state.worldThemeVoteName = val;
  },


  resetForWorldTheme(state) {
    state.worldCountryTheme = "";
    state.worldCountryThemeName = "";
    state.worldCountryThemeVoteListLength = 0;
    state.worldCountryZoneTheme = "";
    state.worldCountryZoneThemeName = "";
    state.worldZoneTheme = "";
    state.worldZoneThemeName = "";
  },
  
  resetForWorldCountryTheme(state) {
    state.worldTheme = "";
    state.worldThemeName = "";
    state.worldCountryZoneTheme = "";
    state.worldCountryZoneThemeName = "";
    state.worldZoneTheme = "";
    state.worldZoneThemeName = "";
  },

  resetForWorldCountryZoneTheme(state) {
    state.worldTheme = "";
    state.worldThemeName = "";
    state.worldCountryTheme = "";
    state.worldCountryThemeName = "";
    state.worldCountryThemeVoteListLength = 0;
    state.worldZoneTheme = "";
    state.worldZoneThemeName = "";
  },

  resetForWorldZoneTheme(state) {
    state.worldTheme = "";
    state.worldThemeName = "";
    state.worldCountryTheme = "";
    state.worldCountryThemeName = "";
    state.worldCountryThemeVoteListLength = 0;
    state.worldCountryZoneTheme = "";
    state.worldCountryZoneThemeName = "";
  },

  resetForWorldZone(state) {
    state.worldCountryZoneTheme = "";
    state.worldCountryZoneThemeName = "";
  },

  resetAllList(state) {
    state.worldTheme = "";
    state.worldThemeName = "";
    state.worldZone = "";
    state.worldZoneName = "";
    state.worldZoneTheme = "";
    state.worldZoneThemeName = "";
    state.worldCountry = "";
    state.worldCountryName = "";
    state.worldCountryTheme = "";
    state.worldCountryThemeName = "";
    state.worldCountryThemeVoteListLength = 0;
    state.worldCountryZone = "";
    state.worldCountryZoneName = "";
    state.worldCountryZoneTheme = "";
    state.worldCountryZoneThemeName = "";
    state.worldCountryZoneThemeVote = "";
    state.worldCountryZoneThemeVoteName = "";
  },

  changeWorldChoice(state, val) {
    state.worldChoice = val
  },

  changeWorldCountryChoice(state, val) {
    state.worldCountryChoice = val
  },

  changeWorldZoneChoice(state, val) {
    state.worldZoneChoice = val
  },

  setPrevWorldZone(state, val) {
    state.prevWorldZone = val
  },
  setPrevWorldCountry(state, val) {
    state.prevWorldCountry = val
  },
  setPrevWorldTheme(state, val) {
    state.prevWorldTheme = val
  },
  setHotList(state, val) {
	state.hotList = val
  }
};
