export default {
  country: (state) => state.route.params.country,
  zone: (state) => state.route.params.zone,
  theme: (state) => state.route.params.theme,
  route: (state) => state.route,
  goback: (state) => state.goback,
  breadcrumb: (state) => {
    if (state.route.params.country) {
      let countryArray = [
        {
          text: state.route.params.country,
          exact: true,
          disabled: false,
          to: {
            name: "country",
            params: { country: state.route.params.country },
          },
        },
      ];
      let zoneArray = [
        {
          text: state.route.params.zone,
          exact: true,
          disabled: false,
          to: {
            name: "zone",
            params: {
              country: state.route.params.country,
              zone: state.route.params.zone,
            },
          },
        },
      ];
      let themeArray = [
        {
          text: state.route.params.theme,
          exact: true,
          disabled: false,
          to: {
            name: "theme",
            params: {
              country: state.route.params.country,
              zone: state.route.params.zone,
              theme: state.route.params.theme,
            },
          },
        },
      ];
      let voteArray = [
        {
          text: state.vote.name,
          exact: true,
          disabled: true,
          to: {
            name: "vote",
            params: {
              country: state.route.params.country,
              zone: state.route.params.zone,
              theme: state.route.params.theme,
              id: state.route.params.id,
            },
          },
        },
      ];

      if (typeof state.route.params.country != "undefined") {
        state.breadcrumb = countryArray;
      }
      if (typeof state.route.params.zone != "undefined") {
        state.breadcrumb = countryArray.concat(zoneArray);
      }
      if (typeof state.route.params.theme != "undefined") {
        state.breadcrumb = countryArray.concat(zoneArray).concat(themeArray);
      }
      if (state.route.name == "vote") {
        state.breadcrumb = countryArray
          .concat(zoneArray)
          .concat(themeArray)
          .concat(voteArray);
      }
    }
    return state.breadcrumb;
  },
};
