// import "firebase/compat/firestore";
import {
  signInWithEmailAndPassword,
  setPersistence,
  updateProfile,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  deleteDoc,
  arrayRemove,
  arrayUnion,
  updateDoc,
  increment,
} from "firebase/firestore";
// import { Auth, PersistenceLOCAL, PersistenceSESSION } from "@/firebase/auth";
// import { Auth, PersistenceLOCAL } from `@/firebase/${process.env.VUE_APP_MODE}/auth`;
let A, D;
if (process.env.VUE_APP_MODE == "production") {
  //import { Auth } from '@/firebase/production/auth';
  A = require("@/firebase/production/auth.js");
  D = require("@/firebase/production/db");
} else {
  A = require("@/firebase/development/auth.js");
  D = require("@/firebase/development/db");
}
const Auth = A.Auth;
const PersistenceLOCAL = A.PersistenceLOCAL;
const DB = D.DB;
import router from "@/router";
import { unwrapFirestoreDoc, setToFirestore } from "firewings";

export default {
  createUser: async ({ commit, dispatch, state }, payload) => {
    try {
      let userData = await createUserWithEmailAndPassword(
        Auth,
        payload.email,
        payload.password
      );
      await updateProfile(userData.user, { displayName: payload.displayName });
      commit("SET_CURRENT_USER", {
        uid: userData.user.uid,
        displayName: payload.displayName,
        metadata: userData.user.metadata,
      });
      // commit(
      //   "SHOW_SNACKBAR",
      //   { title: "Bienvenue " + payload.displayName },
      //   { root: true }
      // );
      // router.replace({
      //   name: "MyAccount",
      // });
      return userData;
    } catch (error) {
      // if (!state.isAuthenticatedUser) {
      // console.log("errreuuuuuuuu", error);
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode == "auth/weak-password") {
        commit(
          "SHOW_SNACKBAR",
          {
            title: "Erreur",
            text: "Le mot de passe est trop faible",
          },
          { root: true }
        );
      } else if (errorCode == "auth/email-already-in-use") {
        commit(
          "SHOW_SNACKBAR",
          {
            title: "Erreur",
            text: "Un Bob avec cet email existe déjà",
          },
          { root: true }
        );
      } else {
        commit(
          "SHOW_SNACKBAR",
          {
            title: "erreur",
            text: `${state.isAuthenticatedUser} rr ${errorMessage}`,
          },
          { root: true }
        );
      }
      return false;
      // }
    }
  },
  setAuthenticatedUser: ({ commit }, payload) => {
    if (payload) {
      commit("authenticateUser", payload.authenticated);
    } else {
      commit("authenticateUser", !Auth.currentUser ? false : true);
    }
  },
  loginUser: async ({ commit, dispatch, state, rootState }, payload) => {
    try {
      // await setPersistence(Auth, browserLocalPersistence);
      let userData = await signInWithEmailAndPassword(
        Auth,
        payload.email,
        payload.password
      );
      await updateProfile(userData.user, { displayName: "Bob" });
      commit("SET_CURRENT_USER", {
        uid: userData.user.uid,
        displayName: "Bob",
        metadata: userData.user.metadata,
        email: userData.user.email,
      });
      dispatch("FETCH_USER_PROFILE", userData.user);
      dispatch("FETCH_USER_VOTES", userData.user);
      return userData;
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode == "auth/user-not-found") {
        commit(
          "SHOW_SNACKBAR",
          {
            title: "Erreur",
            text: "Bob avec cet e-mail n'existe pas.",
          },
          { root: true }
        );
      } else {
        // console.log(errorCode, errorMessage);
        commit(
          "SHOW_SNACKBAR",
          {
            title: "Erreur",
            text: "Il y a eu une erreur.",
          },
          { root: true }
        );
      }
      return false;
    }
  },
  logout: async ({ commit }) => {
    try {
      await signOut(Auth);
      commit("authenticateUser", false);
      commit(
        "SHOW_SNACKBAR",
        {
          title: "Vous avez rangé votre carte d'électeur.",
          text: ` Merci d'avoir changé le monde.`,
        },
        { root: true }
      );
      commit("vote/resetList", null, { root: true });
      commit("vote/resetLastListId", null, { root: true });
      commit("vote/resetLastId", null, { root: true });
      commit("list/resetAllList", null, { root: true });
      // router.replace({
      //   name: "Login",
      // });
    } catch (error) {
      commit(
        "SHOW_SNACKBAR",
        { title: `${error.code} : ${error.message}` },
        { root: true }
      );
    }
  },
  FETCH_USER_PROFILE: async ({ commit }, user) => {
    try {
      const userSnap = await getDoc(doc(DB, "users", user.uid));
      if (userSnap.exists()) {
        const userProfile = await unwrapFirestoreDoc(userSnap);
        commit("SET_USER_PROFILE", userProfile);
      } else {
        // console.log("No such document!");
      }
    } catch (error) {
      // console.log("koko", error);
    }
  },
  FETCH_USER_VOTES: async ({ commit }, user) => {
    try {
      const refUserVotes = await getDocs(
        collection(DB, "users/" + user.uid + "/votes")
      );
      const userVotes = await unwrapFirestoreDoc(refUserVotes);
      commit("SET_USER_VOTES", userVotes);
    } catch (error) {
      // console.log(error);
    }
  },
  refreshMyVote: async ({ commit, state, rootState }, payload) => {
    let path =
      payload.vote.route.params.country !== "undefined"
        ? "/country/" + payload.vote.route.params.country
        : "";
    path +=
      payload.vote.route.params.zone !== "undefined"
        ? "/zone/" + payload.vote.route.params.zone
        : "";
    path +=
      payload.vote.route.params.theme !== "undefined"
        ? "/theme/" + payload.vote.route.params.theme + "/voteList"
        : "";
    let voteSnapshot = await getDoc(
      doc(DB, path, payload.vote.route.params.id)
    );
    const vote = unwrapFirestoreDoc(voteSnapshot);

    let userVote = await getDoc(
      doc(
        DB,
        "/users/" + rootState.user.currentUser.uid + "/votes",
        payload.vote.id
      )
    );
    const myVote = unwrapFirestoreDoc(userVote);

    myVote.choice = vote.choice;

    await setToFirestore(
      doc(DB, "/users/" + state.currentUser.uid + "/votes", payload.id),
      myVote
    );
    const indexz = payload.index;
    commit("updateUserVotes", { myVote: myVote, index: indexz });
  },
  FETCH_USER_PIN_DETAIL: async ({ commit }, user) => {
    try {
      const refUserPinDetail = await getDocs(
        collection(DB, "users/" + user.uid + "/pinDetail")
      );
      const userPinDetail = await unwrapFirestoreDoc(refUserPinDetail);
      commit("SET_USER_PIN_DETAIL", userPinDetail);
    } catch (error) {
      console.log(error);
    }
  },
  initUserPref: async ({ commit, state }, user) => {
    const userProfile = await setToFirestore(
      user.ref,
      {
        pin: [],
        rankUp: [],
        rankDown: [],
      },
      setDoc
    );
    commit("SET_USER_PROFILE", userProfile);
  },
  pin: async ({ commit, state, dispatch, rootState }, payload) => {
    try {
      let pin = [];
      let res = -1;
      if (state.userProfile.pin) {
        res = state.userProfile.pin.indexOf(payload.voteId);
        pin = Object.values({ ...state.userProfile.pin });
      }
      let user = await getDoc(doc(DB, "users", state.currentUser.uid));
      if (!(user && user.exists)) dispatch("initUserPref", user);
      if (res > -1) {
        pin.splice(res, 1);
        // const arrayRemove = FieldValue.arrayRemove;

        await updateDoc(doc(DB, "users", state.currentUser.uid), {
          pin: arrayRemove(payload.voteId),
        });
        // await user.ref.update({
        //   pin: arrayRemove(payload.voteId),
        // });
        await deleteDoc(
          doc(
            DB,
            "users/" + state.currentUser.uid + "/pinDetail",
            payload.voteId
          )
        );
        // await DB.collection("users")
        //   .doc(state.currentUser.uid)
        //   .collection("pinDetail")
        //   .doc(payload.voteId)
        //   .delete();
        commit("removePin", pin);
      } else {
        pin.push(payload.voteId);
        // const arrayUnion = FieldValue.arrayUnion;
        await updateDoc(doc(DB, "users", state.currentUser.uid), {
          pin: arrayUnion(payload.voteId),
        });
        // await user.ref.update({
        //   pin: arrayUnion(payload.voteId),
        // });
        await setDoc(doc(DB, "users/" + state.currentUser.uid + "/pinDetail"), {
          name: rootState.vote.votes[payload.index].name,
          choice: rootState.vote.votes[payload.index].choice,
          route: {
            name: rootState.route.name,
            params: rootState.route.params,
          },
          nbVoters: rootState.vote.votes[payload.index].nbVoters,
        });
        // await DB.collection("users")
        //   .doc(state.currentUser.uid)
        //   .collection("pinDetail")
        //   .doc(payload.voteId)
        //   .set({
        //     name: rootState.vote.votes[payload.index].name,
        //     choice: rootState.vote.votes[payload.index].choice,
        //     route: {
        //       name: rootState.route.name,
        //       params: rootState.route.params,
        //     },
        //     nbVoters: rootState.vote.votes[payload.index].nbVoters,
        //   });
        // await user.ref.update({
        //   pin: arrayUnion({
        //     id: payload.voteId,
        //     name: rootState.vote.votes[payload.index].name,
        //     result: rootState.vote.votes[payload.index].choice,
        //     route: {
        //       name: rootState.route.name,
        //       params: rootState.route.params,
        //     },
        //   }),
        // });
        commit("addPin", pin);
      }
    } catch (error) {
      console.log(error);
    }
  },
  getPinDetail: async ({ commit, state }) => {
    const pinDetails = await getDocs(
      doc(DB, "/users/" + rootState.user.currentUser.uid + "/pinDetail")
    );
    // const pinDetails = await DB.collection("users")
    //   .doc(state.currentUser.uid)
    //   .collection("pinDetail")
    //   .get();
    commit("feedPinDetail", unwrapFirestoreDoc(pinDetails));
  },
  upVoting: async ({ commit, dispatch, state, rootState }, payload) => {
    try {
      if (state.userProfile.hasOwnProperty("rankUp")) {
        if (state.userProfile.rankUp.indexOf(payload.id) == -1) {
          await setDoc(
            doc(DB, payload.path.replace("/" + payload.id, ""), payload.id),
            { rank: increment(1) },
            { merge: true }
          );
        }
      }

      let userVote = await getDoc(
        doc(DB, "users/" + state.currentUser.uid + "/votes", payload.id)
      );
      let uservote = unwrapFirestoreDoc(userVote);
      if (uservote === null) {
        uservote = {};
        // Si l'utilisateur n'a jamais voté, on n'affichera pas dans mes votes :
        // contrôle à faire dans la page mes votes : si pas de score => pas affiché
        // uservote.name = payload.name;
        // uservote.choice = payload.choice;
        // uservote.route = {};
        // uservote.route.name = router.app._route.name;
        // uservote.route.params = router.app._route.params;
      }
      if (uservote.hasOwnProperty("rank")) {
        if (uservote.rank == -1) uservote.rank = 0;
        else if (uservote.rank == 0) uservote.rank = 1;
      } else uservote.rank = 1;

      await setToFirestore(
        doc(DB, "users/" + state.currentUser.uid + "/votes", payload.id),
        uservote,
        null,
        {
          merge: true,
        },
        setDoc
      );

      if (uservote.rank == 0) {
        await setDoc(
          doc(DB, "users", state.currentUser.uid),
          {
            rankUp: arrayRemove(payload.id),
            rankDown: arrayRemove(payload.id),
          },
          { merge: true }
        );
        dispatch("FETCH_USER_PROFILE", { uid: state.currentUser.uid });
      }
      if (uservote.rank == 1) {
        await setDoc(
          doc(DB, "users", state.currentUser.uid),
          {
            rankUp: arrayUnion(payload.id),
            rankDown: arrayRemove(payload.id),
          },
          { merge: true }
        );
        dispatch("FETCH_USER_PROFILE", { uid: state.currentUser.uid });
      }
    } catch (error) {
      console.log(error);
    }
  },
  downVoting: async ({ commit, dispatch, state, rootState }, payload) => {
    try {
      if (state.userProfile.hasOwnProperty("rankDown")) {
        if (state.userProfile.rankDown.indexOf(payload.id) == -1) {
          await setDoc(
            doc(DB, payload.path.replace("/" + payload.id, ""), payload.id),
            { rank: increment(-1) },
            { merge: true }
          );
        }
      }

      let userVote = await getDoc(
        doc(DB, "users/" + state.currentUser.uid + "/votes", payload.id)
      );
      let uservote = unwrapFirestoreDoc(userVote);
      if (uservote === null) {
        uservote = {};
        // idem que dans upvoting
        // uservote.name = payload.name;
        // uservote.choice = payload.choice;
        // uservote.route = {};
        // uservote.route.name = router.app._route.name;
        // uservote.route.params = router.app._route.params;
      }

      if (uservote.hasOwnProperty("rank")) {
        if (uservote.rank == 1) uservote.rank = 0;
        else if (uservote.rank == 0) uservote.rank = -1;
      } else uservote.rank = -1;

      await setToFirestore(
        doc(DB, "users/" + state.currentUser.uid + "/votes", payload.id),
        uservote,
        null,
        {
          merge: true,
        },
        setDoc
      );

      if (uservote.rank == 0) {
        await setDoc(
          doc(DB, "users", state.currentUser.uid),
          {
            rankUp: arrayRemove(payload.id),
            rankDown: arrayRemove(payload.id),
          },
          { merge: true }
        );
        dispatch("FETCH_USER_PROFILE", { uid: state.currentUser.uid });
      }
      if (uservote.rank == -1) {
        await setDoc(
          doc(DB, "users", state.currentUser.uid),
          {
            rankUp: arrayRemove(payload.id),
            rankDown: arrayUnion(payload.id),
          },
          { merge: true }
        );
        dispatch("FETCH_USER_PROFILE", { uid: state.currentUser.uid });
      }
    } catch (error) {
      console.log(error);
    }
  },
};
