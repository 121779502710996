const NotFound = () => import(/* webpackChunkName: "web" */ "@/component/ui/NotFound");

export default [
  // {
  //   path: "/",
  //   redirect: { name: "Login" },
  // },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 (Page non trouvée)",
    },
  },
];
