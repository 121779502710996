export default {
  world: "",
  worldName: "le monde",
  
  worldCountry: "",
  worldCountryName: "",
  
  worldCountryZone: "",
  worldCountryZoneName: "",
  
  worldCountryZoneTheme: "",
  worldCountryZoneThemeName: "",
  
  worldCountryZoneThemeVote: "",
  worldCountryZoneThemeVoteName: "",
  
  worldCountryTheme: "",
  worldCountryThemeName: "",
  worldCountryThemeVoteListLength: 0,

  worldZone: "",
  worldZoneName: "",

  worldZoneTheme: "",
  worldZoneThemeName: "",

  worldTheme: "",
  worldThemeName: "",

  worldCountryZoneType: [
    "communez",
    "départementz"
  ],
  worldChoice: "country",  
  worldCountryChoice: "theme",
  prevWorldZone: "",
  prevWorldCountry: "",
  prevWorldTheme: "",
  worldZoneChoice: "theme",
  hotList: []
};
