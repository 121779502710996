export default {
  myVote: {},
  list: [],
  listParam: [],
  listCountry: [],
  listTheme: [],
  listZone: [],
  worldCountryZoneType: [],
  filter: "",
  lastListId: "",
  lastListCountryId: "",
  lastListThemeId: "",
  lastListZoneId: "",
  votes: [],
  lastId: "",
  electedCriteria: {
    majorite: false,
    ecart: false,
    pour: false,
  },
  electedCriteriaButtonActivated: {
    majorite: "white",
    ecart: "white",
    pour: "white",
  },
  electedMajorite: [],
  electedEcart: [],
  electedPour: [],
  electedCriteriaReset: false,
  vote: {},
  voteCache: [],
  votePath: {},
  countryName: "",
  zoneName: "",
  themeName: "",
  parentZone: [],
  parentTheme: [],
  prevThemePathList: "",
  prevCountryPathList: "",
  prevZonePathList: "",
  subZoneFlag: false,
  subThemeFlag: false,
  queryLimit: 10,
  allVotesLoaded: false,
  displayAllVotes: false
};
