// import { vuexfireMutations } from "vuexfire";

export default {
  authenticateUser(state, val) {
    state.isAuthenticatedUser = val;
  },
  SET_CURRENT_USER(state, payload) {
    state.currentUser = payload || {};
  },
  SET_USER_PROFILE(state, payload) {
    state.userProfile = payload || {};
  },
  SET_USER_VOTES(state, payload) {
    state.userVotes = payload || {};
  },
  updateUserVotes(state, payload) {
    const up = Object.assign({}, state.userVotes);
    up[payload.index] = payload.myVote;
    state.userVotes = up;
  },
  SET_USER_PIN_DETAIL(state, payload) {
    state.userPinDetail = payload || {};
  },
  addPin(state, payload) {
    state.userProfile.pin = payload;
  },
  removePin(state, payload) {
    state.userProfile.pin = payload;
  },
  feedPinDetail(state, payload) {
    state.userPinDetail = payload;
  },
  // ...vuexfireMutations,
};
