// import { App } from "../app";
// import "firebase/firestore";
// import { firestore } from "firebase/app";

// const DB = App.firestore();
// DB.clearPersistence();

// const FieldValue = firestore.FieldValue;
// const Timestamp = firestore.Timestamp;
// export { DB, FieldValue, Timestamp };
import { App } from "../app";
import {
  getFirestore,
  FieldValue,
  Timestamp,
  connectFirestoreEmulator,
  initializeFirestore,
  clearIndexedDbPersistence,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

let DB;

if (
  window.location.hostname !== "192.168.0.49" &&
  window.location.hostname !== "192.168.0.4"  &&
  window.location.hostname !== "192.168.0.5"
) {
  DB = getFirestore(App);
}

if (window.location.hostname === "192.168.0.49") {
  if (window.Cypress) {
    DB = initializeFirestore(App, {
      host: "localhost:8081",
      ssl: false,
      experimentalForceLongPolling: true,
    });
  } else {
    DB = initializeFirestore(App, { host: "192.168.0.49:8081", ssl: false });
  }
}
if (window.location.hostname === "192.168.0.4") {
  if (window.Cypress) {
    // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
    DB = initializeFirestore(App, {
      host: "localhost:8081",
      ssl: false,
      experimentalForceLongPolling: true,
    });
  } else {
    DB = initializeFirestore(App, { host: "192.168.0.4:8081", ssl: false });
  }
}
if (window.location.hostname === "192.168.0.5") {
	if (window.Cypress) {
	  // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
	  DB = initializeFirestore(App, {
		host: "localhost:8081",
		ssl: false,
		experimentalForceLongPolling: true,
	  });
	} else {
	  DB = initializeFirestore(App, { host: "192.168.0.5:8080", ssl: false });
	}
  }

// clearIndexedDbPersistence(App);

// const FieldValue = FieldValue;
// const Timestamp = Timestamp;
export { DB, FieldValue, Timestamp };
